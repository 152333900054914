import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import RichTextEditor from '../RichTextEditor';

function FaqAdding(props) {
  const { question, answer, addNewAnswer, addNewQuestion, handleAddQuestionAnswer } = props;
  // console.log('answer', answer[0]?.children[0]?.text == '');
  console.log('answer', answer);
  return (
    <div>
      <h1>ADD FAQ</h1>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4}>
          <InputLabel>Question</InputLabel>
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            value={question}
            name="faq"
            style={{ width: '100%' }}
            onChange={addNewQuestion}
            required
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputLabel>Answers</InputLabel>
        </Grid>
        <Grid item xs={8} sm={8}>
          <RichTextEditor value={answer} setValue={(val) => addNewAnswer(val)} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item xs={6} sm={6}>
          <Button
            fullWidth
            onClick={() => handleAddQuestionAnswer()}
            disabled={question == ''}
            variant="outlined"
            color="primary"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default FaqAdding;
