import React, { Component } from 'react';
import CorrectorSubscription from '../../components/CorrectorSubscribe';
import {
  getAvailableTutors,
  fetchCorrectorPendingRequest,
  fetchCorrectorSubscribeRequest,
  correctorSubscriptionRequest
} from '../../actions/tutorAction';
import { connect } from 'react-redux';

class CorrectorSubscribeHandle extends Component {
  constructor() {
    super();
    this.state = {
      correctors: '',
      selectCorrector: '',
      subscribedCorrector: '',
      pendingCorrectorRequest: ''
    };
    this.handleCorrectorChange = this.handleCorrectorChange.bind(this);
    this.subscribeCorrector = this.subscribeCorrector.bind(this);
  }
  componentDidMount() {
    const { getAvailableTutors, fetchCorrectorSubscribeRequest, fetchCorrectorPendingRequest } =
      this.props;
    getAvailableTutors();
    fetchCorrectorSubscribeRequest();
    fetchCorrectorPendingRequest();
  }
  componentDidUpdate(preProps) {
    const { correctors, subCorrector, penCorrector } = this.props;
    if (preProps.correctors !== correctors) {
      this.setState({ correctors: correctors });
    } else if (preProps.subCorrector !== subCorrector) {
      this.setState({ subscribedCorrector: subCorrector });
    } else if (preProps.penCorrector !== penCorrector) {
      this.setState({ pendingCorrectorRequest: penCorrector });
    }
  }
  handleCorrectorChange = (e, val) => {
    e.preventDefault();
    this.setState({ selectCorrector: val });
  };
  subscribeCorrector = () => {
    const { selectCorrector } = this.state;
    const { correctorSubscriptionRequest } = this.props;
    correctorSubscriptionRequest(selectCorrector.id);
  };
  render() {
    const { correctors, selectCorrector, subscribedCorrector, pendingCorrectorRequest } =
      this.state;
    return (
      <CorrectorSubscription
        correctors={correctors}
        selectCorrector={selectCorrector}
        handleCorrectorChange={this.handleCorrectorChange}
        subscribeCorrector={this.subscribeCorrector}
        subscribedCorrector={subscribedCorrector}
        pendingCorrectorRequest={pendingCorrectorRequest}
      />
    );
  }
}
const mapStateToProp = (state) => ({
  correctors: state.essayPaperRequest.availableCorrector,
  subCorrector: state.essayPaperRequest.getSubscribedCorrector,
  penCorrector: state.essayPaperRequest.getRequestPendingCorrector
});
const mapDispatchToProp = {
  getAvailableTutors,
  correctorSubscriptionRequest,
  fetchCorrectorSubscribeRequest,
  fetchCorrectorPendingRequest
};

export default connect(mapStateToProp, mapDispatchToProp)(CorrectorSubscribeHandle);
