import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  }
}));

function TypistSubscription(props) {
  const {
    typists,
    selectTypist,
    handleTypistChange,
    subscribeTypist,
    subscribedTypists,
    pendingTypistRequest,
    currentTypingPapers,
    completedTypingPapers
  } = props;
  const classes = useStyles();
  return (
    <DashboardLayout>
      <h1>TypistSubscription is Developping</h1>
      <Card>
        <CardContent>
          <h3>Typist Subscriptions</h3>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Search Typist</InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Autocomplete
                id="typist-id"
                options={typists}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField {...params} label="Select Typist" variant="outlined" />
                )}
                onChange={handleTypistChange}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              {selectTypist && (
                <div>
                  <ul>
                    <li>First Name :- {selectTypist.firstName}</li>
                    <li>Last Name :- {selectTypist.lastName}</li>
                    <li>User Name :- {selectTypist.userName}</li>
                    <li>Email :- {selectTypist.email}</li>
                    <li>Photo :- {selectTypist.profileImage}</li>
                  </ul>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" onClick={subscribeTypist}>
              Subscribe
            </Button>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <h3>Subscribed Typist</h3>
              <Card>
                <CardContent>
                  {subscribedTypists.length >= 1 &&
                    subscribedTypists.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <strong>{index + 1}. </strong>
                          <span>
                            {item.firstName} {item.lastName}
                          </span>
                        </li>
                      </ul>
                    ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6}>
              <h3>Pending Typist</h3>
              <Card>
                <CardContent>
                  {pendingTypistRequest.length >= 1 &&
                    pendingTypistRequest.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <strong>{index + 1}. </strong>
                          <span>
                            {item.firstName} {item.lastName}
                          </span>
                        </li>
                      </ul>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid>
            <h3>Current works</h3>
            <Card>
              <CardContent>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Create Date</TableCell>
                      <TableCell>Need to Finish Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Name of Typist</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTypingPapers.length >= 1 &&
                      currentTypingPapers.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.subject}</TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>23/3/2022</TableCell>
                          <TableCell>29/3/2022</TableCell>
                          <TableCell>{item.typistStatus}</TableCell>
                          <TableCell>
                            {item.typistName} <Button variant="contained">Remove</Button>
                            <Button variant="contained">Change</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <h3>History</h3>
            <Card>
              <CardContent>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Create Date</TableCell>
                      <TableCell>Name of Typist</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedTypingPapers.length >= 1 &&
                      completedTypingPapers.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.subject}</TableCell>
                          <TableCell>23/3/2022</TableCell>
                          <TableCell>{item.typistName}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistSubscription;
