import { getValidToken } from './filterAction';
import {
  FETCH_SUBSCRIPTION_RESULTS_TEST,
  FETCH_AVAILABLE_CORRECTOR,
  FETCH_CORRECTOR_PAPERS_REQUEST,
  FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST,
  FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT,
  FETCH_EXAMPAPER_BY_ATTEMPT_ID,
  FETCH_CORRECTION_HISTORY_PAPERS,
  FETCH_CORRECTOR_TEACHER_REQUEST,
  FETCH_CORRECTOR_PENDING_REQUEST,
  FETCH_CORRECTOR_SUBSCRIBE_REQUEST,
  FETCH_PENDING_PAPERS_BY_CORRECTOR,
  FETCH_ACCEPTED_PAPERS_BY_CORRECTOR
} from '../utils/type';
import { API_URL } from '../utils/helper';
import axios from 'axios';
import { authHeader } from '../utils/helper';

export const getSubscriptionEssayResults = (statuses = '') => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/subscriptions?statuses=${statuses}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_RESULTS_TEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchEssayExamPaperRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/paper-correction-request`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CORRECTOR_PAPERS_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const acceptEssayPaperReaquest = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/corrector/paper-correction-request/accept?examId=${examId}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const rejectEssayPaperRequest = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/corrector/paper-correction-request/reject?examId=${examId}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const fetchAcceptEssayPaperRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/correcting-papers`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getAvailableTutors = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/available-correctors`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_AVAILABLE_CORRECTOR,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllPaperForSelectSubject = (examId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/exam/${examId}/answers-pending-correction`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const uploadImageAnnotation = (questionId, attemptId, annotations, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/${attemptId}/${questionId}/add-annotation`,
        annotations,
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const removeImageAnnotatuon = (questionId, attemptId, annotations, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/${attemptId}/${questionId}/remove-annotation`,
        annotations,
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchEssayPaperbyAttemptId = (attemtId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/exam/attempt/${attemtId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAMPAPER_BY_ATTEMPT_ID,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchCorrectionHistoryPapes = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/corrected-paper/history`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CORRECTION_HISTORY_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchCorrectorTeacherRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/corrector-request`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CORRECTOR_TEACHER_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const correctorAcceptTeacherRequest = (id, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/corrector/corrector-request/confirm?teacherId=${id}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const correctorRejectTeacherRequest = (id, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/corrector/corrector-request/reject?teacherId=${id}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchCorrectorPendingRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/pending-correctors`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CORRECTOR_PENDING_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchCorrectorSubscribeRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/subscribed-correctors`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CORRECTOR_SUBSCRIBE_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const correctorSubscriptionRequest = (id, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/corrector/corrector-request?correctorId=${id}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const patchQuestionMarks = (selectedQuestionId, attemptId, marks, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/${attemptId}/${selectedQuestionId}/set-mark`,
        marks,
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const reAssignCorrector = (examId, correctorId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/corrector/paper-correction-request/reassign?examId=${examId}&correctorId=${correctorId}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getPendingPapersByCorrector = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/corrector-pending-papers-by-teacher-id`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PENDING_PAPERS_BY_CORRECTOR,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const getAcceptedPapersByCorrector = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/corrector/corrector-accepted-papers-by-teacher-id`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ACCEPTED_PAPERS_BY_CORRECTOR,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
