import React from 'react';
import Header from '../components/Header/DashboardHeader';
import NavBar from '../components/NavBar';

export default function TeacherDashboardLayout(props) {
  return (
    <div>
      <div className="main-top-header-wrapper">
        <Header />
        <NavBar />
      </div>
      <main className="main-content-wrapper">{props.children}</main>
    </div>
  );
}
