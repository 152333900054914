import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReadOnly from '../RichTextEditor/ReadOnly';
import Questions from '../ExamPaper/Questions';

const useStyles = makeStyles(() => ({
  questionsContainer: {
    marginTop: 25,
    marginBottom: 50
  },
  questionCard: {
    marginTop: 10,
    marginBottom: 10
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  correctMark: {
    color: 'green',
    marginTop: 35,
    marginBottom: 35
  },
  qnumber: {
    marginLeft: 10,
    marginRight: 20
  },
  ans: {
    marginLeft: 10
  },
  rightPad: {
    paddingRight: '5px'
  }
}));

const QuestionsEditAndReviewSecondPart = (props) => {
  const classes = useStyles();
  const {
    questions,
    selectedQuestionId,
    selectedQuestion,
    handleEdit,
    handleCancel,
    handleQuestionChange,
    submitting,
    handleSaveQuestion,
    handleRejectQuestion
  } = props;

  const displayQuestions = (questionsList) => {
    let displayQuestions = null;
    if (questionsList && questionsList.length > 0) {
      displayQuestions = questionsList.map((questionItem, index) => {
        const questionData = JSON.parse(questionItem.question);
        if (selectedQuestionId === questionItem.id) {
          const selectedQuestionData = JSON.parse(selectedQuestion);
          return (
            <Card className={classes.questionCard} key={questionItem.id}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <div>Question No - {index + 1}</div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Questions
                      input={selectedQuestionData}
                      setInput={(val) => handleQuestionChange(questionItem.id, val)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      disabled={submitting}
                      onClick={handleCancel}
                      color="secondary"
                      variant="contained"
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      onClick={(e) => handleSaveQuestion(e, questionItem.questionNo)}
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Card className={classes.questionCard} key={questionItem.id}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={10}>
                    <div>Question No - {index + 1}</div>
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    {questionItem.examStatus === 'PENDING' ? (
                      <Button color="secondary" variant="outlined" disabled={true}>
                        Rejected
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => handleRejectQuestion(e, questionItem.id)}
                        color="secondary"
                        variant="outlined"
                        disabled={submitting}
                      >
                        Reject
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      onClick={() => handleEdit(questionItem)}
                      color="primary"
                      variant="outlined"
                      disabled={submitting || questionItem.examStatus === 'PENDING'}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReadOnly initialValue={questionData} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        }
      });
    }
    return displayQuestions;
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <div className={classes.questionsContainer}>{displayQuestions(questions)}</div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default QuestionsEditAndReviewSecondPart;
