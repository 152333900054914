import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

const ROLES = [
  {
    value: 'teacher',
    label: 'Teacher'
  },
  {
    value: 'tutor',
    label: 'Corrector'
  },
  {
    value: 'typist',
    label: 'Typist'
  },
  {
    value: 'admin',
    label: 'Admin'
  }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  successMsg: {
    marginTop: theme.spacing(10),
    fontSize: '17px',
    fontWeight: 'bold'
  },
  successHeading: {
    color: 'green'
  },
  errorMsg: {
    color: 'red',
    fontSize: '20px'
  }
}));

export default function SignUp(props) {
  const {
    handleLogin,
    handleChange,
    handleSubmit,
    validationErrors,
    isRegistrationSuccess,
    serverErrorMsg,
    isSubmitting
  } = props;
  const classes = useStyles();

  const options = () =>
    ROLES.map((role) => (
      <MenuItem key={role.value} value={role.value}>
        {role.label}
      </MenuItem>
    ));

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {isRegistrationSuccess ? (
          <div className={classes.successMsg}>
            <Typography className={classes.successHeading} component="h3" variant="h5">
              You have registered Successfully
            </Typography>
            <p>
              We have sent an email to activate your account, Please follow the instructions in the
              email and activate your account before login. If you have already activated your
              account
              <Link onClick={handleLogin} href="#" variant="body2">
                {' '}
                please click here to login.
              </Link>
            </p>
          </div>
        ) : (
          <>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {serverErrorMsg ? <p className={classes.errorMsg}>{serverErrorMsg}</p> : null}
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={handleChange}
                    error={!!validationErrors.firstName}
                    helperText={validationErrors.firstName}
                    inputProps={{
                      maxLength: 25
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={handleChange}
                    error={!!validationErrors.lastName}
                    helperText={validationErrors.lastName}
                    inputProps={{
                      maxLength: 25
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    error={!!validationErrors.email}
                    helperText={validationErrors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="userName"
                    label="User Name"
                    id="userName"
                    onChange={handleChange}
                    error={!!validationErrors.userName}
                    helperText={validationErrors.userName}
                    inputProps={{
                      maxLength: 25
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handleChange}
                    error={!!validationErrors.password}
                    helperText={validationErrors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    onChange={handleChange}
                    error={!!validationErrors.confirmPassword}
                    helperText={validationErrors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="filled-age-native-simple">Select Your role</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="role"
                    onChange={handleChange}
                    error={!!validationErrors.role}
                  >
                    {options()}
                  </Select>
                  <FormHelperText>{validationErrors.role}</FormHelperText>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link onClick={handleLogin} href="#" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </Container>
  );
}
