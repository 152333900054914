import { combineReducers } from 'redux';
import filterReducer from './filterReducer';
import languageReducer from './localizationReducer';
import loaderReducer from './loaderReducer';
import examPaperReducer from './examPaperReducer';
import timerReducer from './timerReducer';
import examPaperHistoryReducer from './examPaperHistoryReducer';
import userReducer from './userReducer';
import typistReducer from './typistReducer';
import correctorReducer from './correctorReducer';
import { faqReducer } from './faqReducer';

const rootReducer = combineReducers({
  filter: filterReducer,
  language: languageReducer,
  loader: loaderReducer,
  examPaper: examPaperReducer,
  timer: timerReducer,
  paperHistory: examPaperHistoryReducer,
  user: userReducer,
  examPaperRequest: typistReducer,
  essayPaperRequest: correctorReducer,
  faq: faqReducer
});

export default rootReducer;
