import React from 'react';
import CSVReader from 'react-csv-reader';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  csvInput: {
    padding: 10,
    display: 'block',
    margin: '15px auto',
    border: '1px solid #ccc',
    borderRadius: 5
  },
  deleteBtn: {
    margin: '15px auto',
    padding: 5
  }
});

const CSVFileReader = (props) => {
  const classes = useStyles();
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_')
  };

  const { handleFileUpload, isFileUploaded, csvFormRef, handleClearCsv } = props;
  return (
    <>
      <form ref={csvFormRef}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={8}>
            <CSVReader
              cssClass={classes.csvInput}
              label="upload .csv file"
              parserOptions={papaparseOptions}
              onFileLoaded={handleFileUpload}
              disabled={isFileUploaded}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {isFileUploaded ? (
              <button className={classes.deleteBtn} type="button" onClick={handleClearCsv}>
                <DeleteIcon />
              </button>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CSVFileReader;
