import { Button, Card, CardContent, FormControl, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NameSelector from '../Selector/NameSelector';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  }
}));
function DeleteSelector(props) {
  const {
    Language,
    Subject,
    Grade,
    Teacher,
    handleChangeClick,
    deleteSubject,
    selector,
    deleteTeacher
  } = props;
  const classes = useStyles();
  const [isEnable, setisEnable] = useState(true);
  useEffect(() => {
    if (Language && Grade && selector?.subject) {
      setisEnable(false);
    }
  }, [Language, Grade, selector?.subject]);
  const addhandleSubject = () => {
    deleteSubject();
  };
  return (
    <Card>
      <CardContent
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'block',
          textAlign: 'center'
        }}
      >
        <Card style={{ backgroundColor: '#EDE4E3' }}>
          <CardContent>
            <h1>Delete Existing Selector</h1>

            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="language"
                  onChange={handleChangeClick}
                  options={Language}
                  placeHolderText="select language"
                />
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="grade"
                  onChange={handleChangeClick}
                  options={Grade}
                  placeHolderText="select grade"
                />
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="subject"
                  onChange={handleChangeClick}
                  options={Subject}
                  placeHolderText="select updating Subject"
                />
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="teacher"
                  onChange={handleChangeClick}
                  options={Teacher}
                  placeHolderText="select updating Teacher"
                />
              </FormControl>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {Teacher && <p>This Subject has a Teacher so delete Teacher First</p>}
              <Button
                variant="contained"
                onClick={selector?.teacher ? deleteTeacher : addhandleSubject}
                disabled={isEnable}
              >
                Delete {Teacher ? 'Teacher' : 'Subject'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}

export default DeleteSelector;
