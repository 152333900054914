import axios from 'axios';
import { API_URL, authHeader } from '../utils/helper';
import {
  FETCH_TEACHER_SUBSCRIPTIONS,
  FETCH_TEACHER_DASHBOARD,
  FETCH_PROFILE_DETAILS
} from '../utils/type';
import { getValidToken } from './filterAction';

export const register = (userData, onsuccess, onFailure) => {
  return async () => {
    axios
      .post(`${API_URL}/user/register`, userData, authHeader())
      .then((res) => {
        onsuccess(res.data);
      })
      .catch((e) => {
        onFailure(e);
        console.error('there is something went wrong', e);
      });
  };
};

export const getTeacherSubscriptions = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/subscriptions/teacher`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_SUBSCRIPTIONS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getDashboardDetails = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/report/teachers/dashboard`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_DASHBOARD,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchProfileDetail = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/profile`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PROFILE_DETAILS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const patchProfileDetail = (profile, onsuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/user/profile`, profile, authHeader(token))
      .then((res) => {
        onsuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
