import React from 'react';
import Select from '@material-ui/core/Select';

const NameSelector = (props) => {
  const { onChange, name, options, placeHolderText, value, error } = props;
  return (
    <Select
      native
      onChange={onChange}
      inputProps={{
        name: `${name}`,
        id: `${name}-native-simple`
      }}
      value={value}
      error={error}
    >
      <option aria-label="None" value="">
        {placeHolderText}
      </option>
      {options.length >= 1 &&
        options.map((option) => (
          <option value={option.name} key={option.id} data-key={option.id} data-img={option.image}>
            {option.name}
          </option>
        ))}
    </Select>
  );
};

export default NameSelector;
