import {
  Card,
  CardContent,
  makeStyles,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button
} from '@material-ui/core';
import React, { useEffect } from 'react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useRef } from 'react';
import { useState } from 'react';
import Compressor from 'compressorjs';
import { useDispatch } from 'react-redux';
import { uploadFiles } from '../../actions/examPaperAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    containers: {
      maxWidth: 600,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
      alignContent: 'center',
      justifyContent: 'end'
    }
  }
}));
export default function Profile(props) {
  const { user, errors, submitting, handleChange, handleUpdate } = props;
  const [profileImage, setProfileImage] = useState('');
  useEffect(() => {
    setProfileImage(user.profileImage);
  }, [user.profileImage]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);
  const handleUploadClick = (e) => {
    const name = e.target.name;
    errors[name] = '';
    const file = e.target.files[0];

    if (!file) {
      errors[name] = 'Please select an image';
      return;
    }
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        const formData = new FormData();
        formData.append('file', result);
        dispatch(
          uploadFiles(formData, (url) => {
            setProfileImage(url);
            user.profileImage = url;
          })
        );
      }
    });
  };
  return (
    <Card style={{ backgroundColor: '#BBC4C2' }}>
      <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <form className={classes.root} noValidate autoComplete="off">
          <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            My Profile
          </h2>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{ backgroundColor: '#B1B1B1', padding: '25px', borderRadius: '100%' }}>
              <img
                src={profileImage}
                style={{ width: '150px', height: '150px', borderRadius: '100%' }}
              />
            </div>
            <div
              style={{
                paddingTop: '125px',
                marginLeft: '-60px',
                cursor: 'pointer' // Add a pointer cursor to indicate it's clickable
              }}
              onClick={() => inputRef.current.click()} // Trigger click on the input when the div is clicked
            >
              <input
                type="file"
                onChange={handleUploadClick}
                name="profileImage"
                ref={(input) => (inputRef.current = input)} // Assign the input ref to a variable
                style={{ display: 'none' }} // Hide the input element
              />
              <CameraAltIcon
                style={{
                  fontSize: 60,
                  padding: '10px',
                  backgroundColor: '#747474',
                  borderRadius: '100%'
                }}
              />
            </div>
          </div>
          <Divider />
          <Grid className={classes.containers} container spacing={2}>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>First Name</InputLabel>
            </Grid>
            <Grid item xs={6} sm={6}>
              <OutlinedInput
                name="firstName"
                onChange={handleChange}
                placeholder=""
                fullWidth
                inputProps={{ 'aria-label': 'firstName' }}
                error={!!errors.firstName}
                value={user.firstName}
              />
              <FormHelperText id="firstName-helper-text">{errors.firstName || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Last Name</InputLabel>
            </Grid>
            <Grid item xs={6} sm={6}>
              <OutlinedInput
                name="lastName"
                fullWidth
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'lastName' }}
                error={!!errors.lastName}
                value={user.lastName}
              />
              <FormHelperText id="LastName-helper-text">{errors.lastName || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>User Name</InputLabel>
            </Grid>
            <Grid item xs={6} sm={6}>
              <OutlinedInput
                name="userName"
                fullWidth
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'userName' }}
                error={!!errors.userName}
                value={user.userName}
              />
              <FormHelperText id="UserName-helper-text">{errors.userName || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>User Email</InputLabel>
            </Grid>
            <Grid item xs={6} sm={6}>
              <OutlinedInput
                name="email"
                fullWidth
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'email' }}
                error={!!errors.email}
                value={user.email}
              />
              <FormHelperText id="email-helper-text">{errors.email || ''}</FormHelperText>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item xs={6} sm={6} alignContent="flex-end">
              <Button
                onClick={handleUpdate}
                fullWidth
                disabled={submitting}
                variant="outlined"
                color="primary"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
