import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchPublishedPapers } from '../../actions/examPaperAction';
import { fetchExamPapers } from '../../actions/examPaperAction';
import PublishExamPaperResult from '../../components/PublishedExamPaper/ResultsCard';
import keycloakService from '../../keycloakService';

const styles = {
  resultGrid: {
    marginTop: 50
  }
};
class PublishedExamPapers extends Component {
  componentDidMount() {
    const { fetchPublishedPapers, fetchExamPapers } = this.props;
    const userDetails = keycloakService.getUserDetails();
    fetchPublishedPapers(userDetails.sub);
    fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' });
  }

  render() {
    const { publishedPapers, classes } = this.props;
    return (
      <Fragment>
        <DashboardLayout>
          <Container className="main-container">
            <div>
              <h2>Published Exam paper</h2>
            </div>
            <div className={classes.resultGrid}>
              <PublishExamPaperResult publishedExamPapers={publishedPapers} />
            </div>
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  publishedPapers: state.examPaper.publishedPapers
});

const mapDispatchToProps = {
  fetchPublishedPapers,
  fetchExamPapers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PublishedExamPapers));
