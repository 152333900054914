import {
  GET_EXAM_PAPER_HISTORY,
  GET_EXAM_PAPER_YEAR_HISTORY,
  GET_EXAM_PAPER_MONTH_BREAKDOWN
} from '../utils/type';

const initialState = {
  paperHistory: [],
  paperYearStats: [],
  paperMonthStats: []
};

const examPaperHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXAM_PAPER_HISTORY:
      return {
        ...state,
        paperHistory: action.data
      };
    case GET_EXAM_PAPER_YEAR_HISTORY:
      return {
        ...state,
        paperYearStats: action.data
      };
    case GET_EXAM_PAPER_MONTH_BREAKDOWN:
      return {
        ...state,
        paperMonthStats: action.data
      };
    default:
      return state;
  }
};

export default examPaperHistoryReducer;
