import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CardActions from '@material-ui/core/CardActions';
import Science from '../PaperHistory/images/science-default.png';

const displayActionBtn = (examData) => {
  let btn = null;
  if (examData.examStatus === 'DRAFT') {
    btn = (
      <a style={{ width: '120px' }} className="mt-3" href={`edit-paper/${examData.id}`}>
        Edit
      </a>
    );
  }

  if (examData.examStatus === 'PENDING') {
    btn = (
      <a style={{ width: '120px' }} className="mt-3" href={`exam-paper/${examData.id}/review`}>
        Edit and Review
      </a>
    );
  }
  return btn;
};

const ExamPaperList = (props) => {
  const { examList, title } = props;
  const renderExamPaperList = (list) => {
    if (list && list.length > 0) {
      return list.map((exam) => (
        <Card className="d-flex paper-card" key={exam.id}>
          <CardMedia
            image={Science}
            title="Live from space album cover"
            style={{ width: '150px' }}
          />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {exam.title || ''}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Subject - {exam.subject || ''}
            </Typography>
          </CardContent>
          <CardActions>
            <div className="d-flex flex-flow-col align-items-center">
              <Alert severity="success">{exam.examStatus || ''}</Alert>
              {displayActionBtn(exam)}
            </div>
          </CardActions>
        </Card>
      ));
    }
  };

  return (
    <div>
      <h3>{title}</h3>
      {renderExamPaperList(examList)}
    </div>
  );
};

export default ExamPaperList;
