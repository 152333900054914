import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingReviewRequest } from '../../actions/typistAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function PaperReviewRequest() {
  const dispatch = useDispatch();
  const pendingReviewRequest = useSelector((state) => state.examPaperRequest.pendingReviewRequest);
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchPendingReviewRequest());
  }, []);
  const handleClick = (id) => {
    history.push(`/exam-paper/${id}/questions`);
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Review Pending Papers
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Teacher Name</TableCell>
                    <TableCell>Subject Name</TableCell>
                    <TableCell>Subject Title</TableCell>
                    <TableCell>Need to finish</TableCell>
                    <TableCell>Review Massage</TableCell>
                    <TableCell>Review Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingReviewRequest.length >= 1 &&
                    pendingReviewRequest.map((acceptPapers, index) => (
                      <TableRow key={index}>
                        <TableCell>{acceptPapers.teacherName}</TableCell>
                        <TableCell>{acceptPapers.subject}</TableCell>
                        <TableCell>{acceptPapers.title}</TableCell>
                        <TableCell>23/03/2023</TableCell>
                        <TableCell>{acceptPapers.reviewComments}</TableCell>
                        <TableCell>
                          {acceptPapers.typistStatus == 'REVIEW_REJECTED' ? (
                            <Button
                              onClick={() => handleClick(acceptPapers.id)}
                              color="primary"
                              style={{ backgroundColor: '#7EC8E3' }}
                            >
                              Edit
                            </Button>
                          ) : (
                            acceptPapers.typistStatus
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default PaperReviewRequest;
