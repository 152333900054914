import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import { fetchAllPaperForSelectSubject } from '../../actions/tutorAction';
import { useDispatch } from 'react-redux';

function CurrentWorkPapers(props) {
  const { examId, handleResultPage } = props;
  const dispatch = useDispatch();
  const papersForSelectedSubject = useSelector(
    (state) => state.essayPaperRequest.allPapersforSubject
  );
  useEffect(() => {
    dispatch(fetchAllPaperForSelectSubject(examId));
  }, []);
  return (
    <Grid item xs={12} sm={12}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Exam Id</strong>
            </TableCell>
            <TableCell>
              <strong>Attempt Id</strong>
            </TableCell>
            <TableCell>
              <strong>Status</strong>
            </TableCell>
            <TableCell>
              <strong>Action</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {papersForSelectedSubject &&
            papersForSelectedSubject.length >= 1 &&
            papersForSelectedSubject.map((acceptPapers, index) => (
              <TableRow key={index}>
                <TableCell>{acceptPapers.examId}</TableCell>
                <TableCell>{acceptPapers.id}</TableCell>
                <TableCell>{acceptPapers.status}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => handleResultPage(acceptPapers)}>
                    Start Correction
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

export default CurrentWorkPapers;
