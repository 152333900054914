import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  container: {
    maxWidth: 600
  },
  message: {
    color: 'red'
  },
  formWrapper: {
    margin: 5,
    padding: 5
  },
  emailList: {
    display: 'flex'
  },
  deleteIcon: {
    color: 'red',
    cursor: 'pointer'
  }
});

const NotFoundStudentList = (props) => {
  const { handleChangeEmail, handleUpdateDetails, errorMsg, handleDelete, unfoundEmails } = props;
  const [studentEmailList, setStudentEmailList] = useState([]);
  const styles = useStyles();
  useEffect(() => {
    if (unfoundEmails) {
      setStudentEmailList(unfoundEmails);
    }
  }, [unfoundEmails]);

  return (
    <form className={styles.formWrapper}>
      {studentEmailList.length > 0 ? (
        <Grid className={styles.container} container spacing={2}>
          {errorMsg ? <p className={styles.message}>{errorMsg}</p> : null}
          <p className={styles.message}>
            **Please check below emails, no students found for below emails in the system
          </p>
          {studentEmailList.map((studentEmail, index) => (
            <Grid item xs={12} sm={8} key={index}>
              <div className={styles.emailList}>
                <Input
                  name={`email-${index}`}
                  value={studentEmail}
                  placeholder=""
                  inputProps={{ 'aria-label': 'title' }}
                  fullWidth
                  onChange={(e) => handleChangeEmail(e, index)}
                />
                <DeleteIcon
                  onClick={() => handleDelete(studentEmail)}
                  className={styles.deleteIcon}
                />
              </div>
            </Grid>
          ))}
          <Grid item xs={12} sm={8}>
            <Button onClick={handleUpdateDetails} fullWidth variant="outlined" color="primary">
              Update Details
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </form>
  );
};

export default NotFoundStudentList;
