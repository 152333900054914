import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReadOnly from '../RichTextEditor/ReadOnly';
import RichTextEditor from '../RichTextEditor';
import { Button, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    width: '100%'
  },
  boxSpacing: {
    padding: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  ponints: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20
  },
  iconSize: {
    fontSize: 30
  }
}));
const renderNode = (faq, handleFaqChange, handleUpdateBtn, selectedFaqId, selectedFaqAnswer) => {
  const faqNode = JSON.parse(faq.answer);
  return handleUpdateBtn ? (
    <RichTextEditor
      value={faq.id == selectedFaqId ? JSON.parse(selectedFaqAnswer) : JSON.parse(faq.answer)}
      setValue={(val) => handleFaqChange(val)}
    />
  ) : (
    faqNode.map((item, index) => {
      const arr = [item];
      // console.log('arr', arr);
      switch (item.type) {
        case 'paragraph':
          return (
            <div key={index}>
              <ReadOnly initialValue={arr} />
            </div>
          );
        case 'image':
          return (
            <img
              key={index}
              src={item.src}
              alt={item.alt}
              style={{ maxWidth: '50%', height: 'auto' }}
            />
          );
        default:
          return null;
      }
    })
  );
};
function FaqHandler(props) {
  const {
    faqDetail,
    handleFaqChange,
    handleEditSetData,
    selectedFaqId,
    selectedFaqQuestion,
    selectedFaqAnswer,
    handleFaqQuesChange,
    handleDeleteFaq,
    handleUpdateChanges
  } = props;
  const [handleUpdateBtn, setHandleButtons] = useState(false);
  const classes = useStyles();
  const handleEdit = (faq) => {
    setHandleButtons(!handleUpdateBtn);
    handleEditSetData(faq);
  };
  const handleUpdate = () => {
    setHandleButtons(!handleUpdateBtn);
    handleUpdateChanges();
  };
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <h1>UPDATE FAQ</h1>
        {faqDetail.length >= 1 &&
          faqDetail.map((faq) => (
            <Accordion key={faq.id}>
              <Grid style={{ display: 'flex' }}>
                <Grid item xs={8} sm={8}>
                  {handleUpdateBtn == false ? (
                    <Typography className={classes.heading}>{faq.question}</Typography>
                  ) : (
                    <TextField
                      value={faq.id == selectedFaqId ? selectedFaqQuestion : faq.question}
                      style={{ width: '100%' }}
                      name="faq"
                      onChange={handleFaqQuesChange}
                    />
                  )}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setHandleButtons(false)}
                  ></AccordionSummary>
                </Grid>
              </Grid>

              <AccordionDetails>
                <Grid container spacing={3} className={classes.boxSpacing}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        justifyContent: 'flex-end',
                        margin: 'auto',
                        alignItems: 'flex-end',
                        display: 'flex'
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          display: handleUpdateBtn == false ? 'block' : 'none',
                          margin: '5px'
                        }}
                        onClick={() => handleEdit(faq)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#59981A',
                          color: 'white',
                          display: handleUpdateBtn == true ? 'block' : 'none',
                          margin: '5px'
                        }}
                        onClick={() => handleUpdate()}
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: '5px' }}
                        onClick={() => handleDeleteFaq(faq.id)}
                      >
                        Delete
                      </Button>
                    </div>
                    <div className={classes.ponints}>
                      {renderNode(
                        faq,
                        handleFaqChange,
                        handleUpdateBtn,
                        selectedFaqId,
                        selectedFaqAnswer
                      )}
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </Fragment>
  );
}

export default FaqHandler;
