import axios from 'axios';
import {
  FECTH_FILTER_SEARCH_DATA,
  CLEAR_FILTER_SEARCH,
  FETCH_ROOT_FILTER_DATA,
  FETCH_FILTER_DATA_BY_TITLE,
  REMOVE_FILTERS_DATA,
  FETCH_SUBSCRIPTION_RESULTS,
  SET_FILTER_INDEX,
  SET_FILTER_VALUES,
  FETCH_SELECTOR_ALL,
  FETCH_CASCADING_FILTER_RESULT
} from '../utils/type';
import { API_URL, authHeader } from '../utils/helper';
import keycloakService from '../keycloakService';

export const setFilterIndex = (index) => {
  return {
    type: SET_FILTER_INDEX,
    data: index
  };
};

export const setFilterValues = (values) => {
  return {
    type: SET_FILTER_VALUES,
    data: values
  };
};

const getFilterValues = (queryData) => {
  const values = [];
  if (queryData) {
    Object.values(queryData).forEach((val) => {
      values.push(val);
    });
  }
  return values;
};

export const getValidToken = async () => {
  const isLoggedIn = keycloakService.isLoggedIn();
  if (isLoggedIn) {
    await keycloakService.updateToken();
    const token = keycloakService.getToken();
    return token;
  }
  return keycloakService.login();
};

export const saveFilterDataByTitle = (data, filterIndex) => {
  if (data && data.length) {
    const { searchTitle, parent, displayTitle } = data[0];
    if (parent === 'root') {
      return {
        type: FETCH_ROOT_FILTER_DATA,
        data: {
          displayTitle: displayTitle,
          options: data
        }
      };
    } else {
      return {
        type: FETCH_FILTER_DATA_BY_TITLE,
        data: {
          searchTitle: searchTitle,
          filterIndex,
          filterData: {
            displayTitle: displayTitle,
            options: data
          }
        }
      };
    }
  }
};

export const getFilterData = async (filterType) => {
  try {
    const res = await axios.get(`${API_URL}/exam/filter?type=${filterType}`);
    return res ? res.data : [];
  } catch (e) {
    console.log('there is an error', e);
    return false;
  }
};

export const getSelecterAll = () => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/selector/all`)
      .then((res) => {
        dispatch({
          type: FETCH_SELECTOR_ALL,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('something went wrong in getSelecterAll call', e);
      });
  };
};
export const cascadeFilter = (filter) => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/selector/get-by-parent-id/${filter}`)
      .then((res) => {
        dispatch({
          type: FETCH_CASCADING_FILTER_RESULT,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('something went wrong in cascadeFilter', e);
      });
  };
};
export const fetchSearchResult = (searchData, onsuccess = () => {}) => {
  return async (dispatch) => {
    let page = 0;
    let pageSize = 3;
    if (searchData.page) {
      page = searchData.page;
    }
    if (searchData.pageSize) {
      pageSize = searchData.pageSize;
    }

    const filterValues = getFilterValues(searchData.query);
    const searchUrl = `${API_URL}/exam/paper/search?page=${page}&size=${pageSize}&sort=title,desc&sort=description,desc&filters=${filterValues}`;
    axios
      .get(searchUrl)
      .then((res) => {
        dispatch({
          type: FECTH_FILTER_SEARCH_DATA,
          data: res.data
        });
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const clearFilterAndSearchResult = () => {
  return {
    type: CLEAR_FILTER_SEARCH
  };
};

export const cleanForwardFilters = (filterIndex) => {
  return {
    type: REMOVE_FILTERS_DATA,
    data: {
      filterIndex
    }
  };
};

export const getSubscriptionResults = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/subscriptions?statuses`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_RESULTS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const addNewSelector = (selector, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/selector`, selector, authHeader(token))
      .then((res) => {
        onsuccess(res);
        console.log('res', res);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const addNewTeacherSelector = (selector, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/selector/add/teacher`, selector, authHeader(token))
      .then((res) => {
        onsuccess(res);
        console.log('res', res);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const updateNewSelector = (selector, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/selector`, selector, authHeader(token))
      .then((res) => {
        onsuccess(res);
        console.log('res', res);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const DeleteNewSelector = (parent, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .delete(`${API_URL}/selector/${parent}`, authHeader(token))
      .then((res) => {
        onsuccess(res);
        console.log('res', res);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
