import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import Annotation from '../../components/Annotation';
import CorrectionQuestion from './CorrectionQuestion';
import { fetchEssayPaperbyAttemptId, patchQuestionMarks } from '../../actions/tutorAction';
import { connect } from 'react-redux';
import { removeImageAnnotatuon } from '../../actions/tutorAction';

class AnnotationResult extends Component {
  constructor() {
    super();
    this.state = {
      results: [],
      renderQuestion: true,
      selectedQuestionId: '',
      currentQuestionNo: 1,
      uploadFileURL: '',
      noOfQuestion: 0,
      attemptId: '',
      questionData: '',
      marks: ''
    };
    this.goToEssayQuestion = this.goToEssayQuestion.bind(this);
    this.goToAnswersPage = this.goToAnswersPage.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleAnnotate = this.handleAnnotate.bind(this);
    this.handleRemoveAnnotate = this.handleRemoveAnnotate.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleMark = this.handleMark.bind(this);
  }
  componentDidMount() {
    const { essayAnswers, fetchEssayPaperbyAttemptId } = this.props;
    fetchEssayPaperbyAttemptId(essayAnswers.id);
    this.setState({
      attemptId: essayAnswers.id,
      noOfQuestion: Object.keys(essayAnswers.questionAttemptMap).length
    });
  }
  componentDidUpdate(preProps) {
    const { newAnnotation } = this.props;
    const { currentQuestionNo } = this.state;
    if (preProps.newAnnotation !== newAnnotation) {
      const attemptId = newAnnotation.id || '';
      const noOfQuestion = Object.keys(newAnnotation.questionAttemptMap).length;
      this.setState({
        attemptId,
        noOfQuestion,
        results: newAnnotation.questionAttemptMap,
        marks: Object.values(newAnnotation.questionAttemptMap)[currentQuestionNo - 1].mark
      });
    }
  }
  goToEssayQuestion(e, questionData, questionNo) {
    e.preventDefault();
    if (questionData.questionId && questionNo) {
      this.setState({
        selectedQuestionId: questionData.questionId,
        currentQuestionNo: questionNo,
        uploadFileURL: questionData.essayAnswers,
        marks: questionData.mark,
        renderQuestion: false
      });
    }
  }
  goToAnswersPage(e) {
    const { attemptId } = this.state;
    const { fetchEssayPaperbyAttemptId } = this.props;
    e.preventDefault();
    fetchEssayPaperbyAttemptId(attemptId);
    this.setState({
      renderQuestion: true
    });
  }
  handleNext(e) {
    e.preventDefault();
    const { results, currentQuestionNo, attemptId } = this.state;
    const { fetchEssayPaperbyAttemptId } = this.props;
    const previousQuestionId = results[currentQuestionNo + 1].questionId;
    this.setState({
      selectedQuestionId: previousQuestionId,
      currentQuestionNo: currentQuestionNo + 1,
      marks: ''
    });
    fetchEssayPaperbyAttemptId(attemptId);
  }
  handlePrevious(e) {
    e.preventDefault();
    const { results, currentQuestionNo, attemptId } = this.state;
    const { fetchEssayPaperbyAttemptId } = this.props;
    const previousQuestionId = results[currentQuestionNo - 1].questionId;
    this.setState({
      selectedQuestionId: previousQuestionId,
      currentQuestionNo: currentQuestionNo - 1,
      marks: ''
    });
    fetchEssayPaperbyAttemptId(attemptId);
  }
  handleAnnotate() {
    const { fetchEssayPaperbyAttemptId } = this.props;
    const { attemptId } = this.state;
    fetchEssayPaperbyAttemptId(attemptId);
  }
  handleRemoveAnnotate(questionId, annotationData) {
    const { fetchEssayPaperbyAttemptId, removeImageAnnotatuon } = this.props;
    const { attemptId } = this.state;
    removeImageAnnotatuon(questionId, attemptId, annotationData, () => {
      fetchEssayPaperbyAttemptId(attemptId);
    });
  }
  handleMark(e) {
    const { value } = e.target;
    this.setState({ marks: value });
  }
  handleButtonClick() {
    const { patchQuestionMarks } = this.props;
    const { selectedQuestionId, attemptId, marks } = this.state;
    const Mark = { mark: marks };
    patchQuestionMarks(selectedQuestionId, attemptId, Mark);
  }
  render() {
    const {
      renderQuestion,
      selectedQuestionId,
      currentQuestionNo,
      noOfQuestion,
      attemptId,
      results,
      marks
    } = this.state;
    return (
      <DashboardLayout>
        {renderQuestion ? (
          <Annotation results={results} goToEssayQuestion={this.goToEssayQuestion} />
        ) : (
          <CorrectionQuestion
            results={results}
            noOfQuestion={noOfQuestion}
            currentQuestionId={selectedQuestionId}
            currentQuestionNo={currentQuestionNo}
            attemptId={attemptId}
            goToAnswersPage={this.goToAnswersPage}
            handleNext={this.handleNext}
            handlePrevious={this.handlePrevious}
            handleAnnotate={this.handleAnnotate}
            handleRemoveAnnotate={this.handleRemoveAnnotate}
            handleButtonClick={this.handleButtonClick}
            handleMark={this.handleMark}
            marks={marks}
          />
        )}
      </DashboardLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  newAnnotation: state.essayPaperRequest.examPaperByAttemptId
});
const mapDispatchToProps = {
  fetchEssayPaperbyAttemptId,
  patchQuestionMarks,
  removeImageAnnotatuon
};
export default connect(mapStateToProps, mapDispatchToProps)(AnnotationResult);
