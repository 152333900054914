import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import ReadOnly from '../RichTextEditor/ReadOnly';
import Questions from '../ExamPaper/Questions';
import Answers from '../ExamPaper/Answer';
import { ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  questionsContainer: {
    marginTop: 25,
    marginBottom: 50
  },
  questionCard: {
    marginTop: 10,
    marginBottom: 10
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  correctMark: {
    color: 'green',
    marginTop: 35,
    marginBottom: 35
  },
  qnumber: {
    marginLeft: 10,
    marginRight: 20
  },
  ans: {
    marginLeft: 10
  },
  rightPad: {
    paddingRight: '5px'
  }
}));
const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
const QuestionsEditAndReview = (props) => {
  const classes = useStyles();
  const {
    questions,
    selectedQuestionId,
    selectedQuestion,
    selectedQuestionAnswers,
    handleEdit,
    handleCancel,
    handleQuestionChange,
    selectedAnswer,
    submitting,
    handleAnswerChange,
    handleRadioChange,
    handleSaveQuestion,
    handleRejectQuestion,
    examPaperDetails
  } = props;
  const handleGrpQuestion = (index) => {
    let questionObj = { show: 'none', question: questionInitialBlock };
    const grpQuestion = examPaperDetails.groupQuestions;
    if (grpQuestion.length >= 1) {
      for (let i = 0; i < grpQuestion.length; i++) {
        const item = grpQuestion[i];
        if (item.startQuestion <= index && index <= item.endQuestion) {
          questionObj = {
            question: JSON.parse(item.grpQuestion),
            show: ''
          };
          break;
        } else {
          questionObj = { question: questionInitialBlock, show: 'none' };
        }
      }
    }
    return questionObj;
  };
  const displayQuestions = (questionsList) => {
    let displayQuestions = null;
    if (questionsList && questionsList.length > 0) {
      displayQuestions = questionsList.map((questionItem, index) => {
        const questionData = JSON.parse(questionItem.question);
        if (selectedQuestionId === questionItem.id) {
          const selectedQuestionData = JSON.parse(selectedQuestion);
          return (
            <Card className={classes.questionCard} key={questionItem.id}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <div>Question No - {index + 1}</div>
                  </Grid>
                  <div style={{ display: handleGrpQuestion(index + 1).show, margin: '10px' }}>
                    <p className={classes.ans}>
                      <strong>Group Question</strong>
                    </p>
                    <Grid item xs={12} sm={12}>
                      <ReadOnly initialValue={handleGrpQuestion(index + 1).question} />
                    </Grid>
                  </div>
                  <Grid container style={{ paddingLeft: '20px' }}>
                    <Grid item xs={12} sm={12}>
                      <Questions
                        input={selectedQuestionData}
                        setInput={(val) => handleQuestionChange(questionItem.id, val)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <p>Answers</p>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      {selectedQuestionAnswers &&
                        selectedQuestionAnswers.map((answerItem, index) => (
                          <Fragment key={`answer-${index}`}>
                            <Grid item xs={12} sm={10}>
                              <Answers
                                key={index}
                                input={JSON.parse(answerItem.answer)}
                                setInput={(val) => handleAnswerChange(index, val)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Radio
                                checked={selectedAnswer === `answer-${index}`}
                                value={`answer-${index}`}
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': `${index}` }}
                                onChange={handleRadioChange}
                              />
                              Marked As Correct
                            </Grid>
                          </Fragment>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      disabled={submitting}
                      onClick={handleCancel}
                      color="secondary"
                      variant="contained"
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      onClick={(e) => handleSaveQuestion(e, questionItem.questionNo)}
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Card className={classes.questionCard} key={questionItem.id}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={10}>
                    <div>Question No - {index + 1}</div>
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    {questionItem.examStatus === 'PENDING' ? (
                      <Button color="secondary" variant="outlined" disabled={true}>
                        Rejected
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => handleRejectQuestion(e, questionItem.id)}
                        color="secondary"
                        variant="outlined"
                        disabled={submitting}
                      >
                        Reject
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={1} className={classes.cardAction}>
                    <Button
                      onClick={() => handleEdit(questionItem)}
                      color="primary"
                      variant="outlined"
                      disabled={submitting || questionItem.examStatus === 'PENDING'}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <div style={{ display: handleGrpQuestion(index + 1).show, margin: '10px' }}>
                    <p className={classes.ans}>
                      <strong>Group Question</strong>
                    </p>
                    <Grid item xs={12} sm={12}>
                      <ReadOnly initialValue={handleGrpQuestion(index + 1).question} />
                    </Grid>
                  </div>
                  <Grid container style={{ paddingLeft: '20px' }}>
                    <Grid item xs={12} sm={12}>
                      <ReadOnly initialValue={questionData} />
                    </Grid>
                  </Grid>
                  <p className={classes.ans}>
                    <strong>Answers</strong>
                  </p>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      {questionItem.answers &&
                        questionItem.answers.map((answerItem, index) => (
                          <>
                            <ListItem
                              key={index + 1}
                              style={{ backgroundColor: answerItem.valid ? '#99cc99' : '' }}
                            >
                              <lable className={classes.qnumber}>{index + 1}.</lable>
                              <ListItemText style={{ paddingBottom: '1px' }}>
                                <ReadOnly
                                  key={index + 1}
                                  initialValue={JSON.parse(answerItem.answer)}
                                />
                              </ListItemText>
                            </ListItem>
                          </>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        }
      });
    }
    return displayQuestions;
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <div className={classes.questionsContainer}>{displayQuestions(questions)}</div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default QuestionsEditAndReview;
