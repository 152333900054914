import React, { Component } from 'react';
import { connect } from 'react-redux';
import Register from '../../components/Register';
import { validateUser } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import { register } from '../../actions/userAction';

class Registration extends Component {
  constructor() {
    super();
    this.state = {
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        password: '',
        confirmPassword: '',
        role: ''
      },
      errors: {},
      isRegistrationSuccess: false,
      serverErrorMsg: '',
      isSubmitting: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { userData, errors } = this.state;
    const { name, value } = e.target;
    userData[name] = value;
    errors[name] = '';
    this.setState({ userData, errors, serverErrorMsg: '' });
  }

  handleLogin() {
    keycloakService.login();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { userData } = this.state;
    const { register } = this.props;
    const { isValid, validationErrors } = validateUser(userData);
    if (!isValid) {
      this.setState({ errors: validationErrors });
    } else {
      this.setState({ isSubmitting: true });
      delete userData.confirmPassword;
      register(
        userData,
        () => {
          this.setState({ isSubmitting: false, isRegistrationSuccess: true });
        },
        (e) => {
          const { response } = e;
          const message =
            response && response.examStatus === 409
              ? response.data
              : 'Please check your details and try again!';
          this.setState({
            isSubmitting: false,
            serverErrorMsg: message
          });
        }
      );
    }
  }

  render() {
    const { errors, isRegistrationSuccess, serverErrorMsg, isSubmitting } = this.state;
    return (
      <Register
        handleLogin={this.handleLogin}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        isRegistrationSuccess={isRegistrationSuccess}
        serverErrorMsg={serverErrorMsg}
        validationErrors={errors}
        isSubmitting={isSubmitting}
      />
    );
  }
}

const mapDispatchToProps = {
  register
};

export default connect(null, mapDispatchToProps)(Registration);
