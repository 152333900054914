import { Route } from 'react-router-dom';
import keycloakService from '../keycloakService';
import { Redirect } from 'react-router-dom';

const GuestElement = ({ Component, ...rest }) => {
  const isAuthenticated = keycloakService.isLoggedIn();
  return isAuthenticated ? <Redirect to="/" /> : <Component {...rest} />;
};

const GuestRoute = ({ component, requiredRoles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <GuestElement {...props} Component={component} requiredRoles={requiredRoles} />;
      }}
    />
  );
};

export default GuestRoute;
