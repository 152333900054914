import React from 'react';
import RichTextEditor from '../RichTextEditor';

const Questions = (props) => {
  const { input, setInput } = props;
  return (
    <div>
      <RichTextEditor value={input} setValue={setInput} />
    </div>
  );
};

export default Questions;
