import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './header.css';
import './style.css';
import keycloakService from '../../keycloakService';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../actions/localizationAction';

const Header = () => {
  const isLoggedIn = keycloakService.isLoggedIn();
  const userName = keycloakService.getUserName();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;

  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    keycloakService.login();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    keycloakService.logout();
  };

  const handleRegister = (e) => {
    e.preventDefault();
    keycloakService.register();
  };

  const handleLanguageChange = (e) => {
    localStorage.setItem('language', e.target.value);
    dispatch(setLanguage(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <header className="site-header">
      <div className="wrapper site-header__wrapper">
        <div className="site-header__start">
          <a href="/" className="brand">
            {t('header_logo')}
          </a>
        </div>
        <div className="site-header__middle">
          <nav className="nav">
            <button className="nav__toggle" aria-expanded="false" type="button">
              menu
            </button>
            <ul className="nav__wrapper">
              <li className="nav__item">
                <a href="/#">{t('header_home')}</a>
              </li>
              <li className="nav__item">
                <a href="/exams">{t('header_exams')}</a>
              </li>
              <li className="nav__item">
                <a href="/#">{t('header_faq')}</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-header__end">
          <ul className="nav__wrapper">
            {isLoggedIn ? (
              <li className="auth__item">
                <div className="dropdown">
                  <button className="dropbtn">{userName}</button>
                </div>
              </li>
            ) : null}
            {isLoggedIn ? (
              <li className="auth__item">
                <a onClick={handleLogout} className="button" href="/#">
                  {t('header_logout_btn_text')}
                </a>
              </li>
            ) : (
              <li className="auth__item">
                <a onClick={handleLogin} className="button" href="/#">
                  {t('header_login_btn_text')}
                </a>
              </li>
            )}
            {!isLoggedIn ? (
              <li className="auth__item">
                <a onClick={handleRegister} className="button" href="/#">
                  {t('header_register_btn_text')}
                </a>
              </li>
            ) : null}
            <li className="auth__item">
              <select
                className="lang-select"
                name="languages"
                value={language}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="tamil">Tamil</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
