import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

export default class TypistHome extends Component {
  render() {
    return (
      <DashboardLayout>
        <h1>This is Typist Dashboard is Comming Soon..!</h1>
      </DashboardLayout>
    );
  }
}
