import {
  FETCH_ROOT_FILTER_DATA,
  FETCH_FILTER_DATA_BY_TITLE,
  CLEAR_FILTER_SEARCH,
  REMOVE_FILTERS_DATA,
  FECTH_FILTER_SEARCH_DATA,
  FETCH_SUBSCRIPTION_RESULTS,
  SET_FILTER_INDEX,
  SET_FILTER_VALUES,
  FETCH_SELECTOR_ALL,
  FETCH_CASCADING_FILTER_RESULT
} from '../utils/type';

const initialState = {
  filterData: {
    rootFilter: {}
  },
  searchData: {
    content: []
  },
  subscriptionResult: [],
  filterIndex: 0,
  filterValues: {},
  selectors: {
    Category: [],
    Grade: [],
    Subject: [],
    Teacher: []
  },
  cascadeFilterResult: []
};

const getCurrentFiltersData = (currentState, newFilterData) => {
  const keys = Object.keys(currentState.filterData);
  const selectedFilterIndex = newFilterData.filterIndex;
  let filtersData = currentState.filterData;
  if (selectedFilterIndex >= 0) {
    const filteredKeys = keys.filter((key, keyIndex) => keyIndex <= selectedFilterIndex);
    filtersData = filteredKeys.reduce((obj, key) => {
      obj[key] = currentState.filterData[key];
      return obj;
    }, {});
  }
  return filtersData;
};

const filterReducer = (state = initialState, action) => {
  let newFilterData = {};
  let oldFilterContents = [];
  let newSearchData = {};
  switch (action.type) {
    case FETCH_ROOT_FILTER_DATA:
      return {
        ...state,
        filterData: {
          rootFilter: action.data
        }
      };
    case FETCH_FILTER_DATA_BY_TITLE:
      newFilterData = getCurrentFiltersData(state, action.data);
      newFilterData[action.data.searchTitle] = action.data.filterData;
      return {
        ...state,
        filterData: newFilterData
      };
    case CLEAR_FILTER_SEARCH:
      return {
        ...state,
        filterData: {
          rootFilter: state.filterData.rootFilter
        },
        searchData: {
          content: []
        }
      };
    case REMOVE_FILTERS_DATA:
      newFilterData = getCurrentFiltersData(state, action.data);
      return {
        ...state,
        filterData: newFilterData
      };
    case FECTH_FILTER_SEARCH_DATA:
      oldFilterContents = state.searchData.content;
      newSearchData = action.data;
      newSearchData.content = [...oldFilterContents, ...newSearchData.content];
      return {
        ...state,
        searchData: newSearchData
      };
    case FETCH_SUBSCRIPTION_RESULTS:
      return {
        ...state,
        subscriptionResult: action.data
      };
    case SET_FILTER_INDEX:
      return {
        ...state,
        filterIndex: action.data
      };
    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.data
      };
    case FETCH_SELECTOR_ALL:
      return {
        ...state,
        selectors: action.data
      };
    case FETCH_CASCADING_FILTER_RESULT:
      return {
        ...state,
        cascadeFilterResult: action.data
      };
    default:
      return state;
  }
};

export default filterReducer;
