import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import keycloakService from '../../keycloakService';
import TeacherDashboardLayout from '../../layouts/DashboardLayout';
import { fetchTeacherPapers, fetchExamPaperStatByBatch } from '../../actions/examPaperAction';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

const BatchResults = () => {
  const [examDetails, setExamDetails] = useState({ examId: '', batch: '' });
  const { sub } = keycloakService.getUserDetails();
  const dispatch = useDispatch();

  const { teacherExamPapers, batchResults } = useSelector((state) => state.examPaper);

  useEffect(() => {
    dispatch(fetchTeacherPapers(sub, 'PUBLISHED'));
  }, []);

  const handlePaperChange = (e, examPaper) => {
    setExamDetails({
      ...examDetails,
      examId: examPaper ? examPaper.id : ''
    });
  };

  const handleChange = (e) => {
    setExamDetails({
      ...examDetails,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchExamPaperStatByBatch(examDetails));
  };

  return (
    <TeacherDashboardLayout>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={4}>
            <Autocomplete
              id="exam-id"
              options={teacherExamPapers || []}
              getOptionLabel={(option) => option.title || ''}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Select Exam paper" variant="outlined" />
              )}
              onChange={handlePaperChange}
              value={teacherExamPapers.find((a) => a.id === examDetails.examId)}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              id="batch"
              fullWidth
              label="Batch"
              variant="outlined"
              name="batch"
              onChange={handleChange}
              value={examDetails.batch}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Button
              disabled={!examDetails.examId || !examDetails.batch}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              View results
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={6} md={12}>
            <Typography variant="h5" gutterBottom>
              {batchResults.examTitle || ''}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={6} md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Exam Status</TableCell>
                    <TableCell>Score (%)</TableCell>
                    <TableCell>Time Spent (Minute)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchResults.examBatchCandidateStatList &&
                    batchResults.examBatchCandidateStatList.map((examBatchCandidate, index) => (
                      <TableRow
                        key={examBatchCandidate.examAttemptId}
                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{examBatchCandidate.candidateName}</TableCell>
                        <TableCell>{examBatchCandidate.candidateEmail}</TableCell>
                        <TableCell>{examBatchCandidate.examAttemptStatus}</TableCell>
                        <TableCell>
                          {examBatchCandidate.score ? Math.ceil(examBatchCandidate.score) : 'NA'}
                        </TableCell>
                        <TableCell>
                          {examBatchCandidate.totalTimeSpentInMin
                            ? examBatchCandidate.totalTimeSpentInMin
                            : 'NA'}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </TeacherDashboardLayout>
  );
};

export default BatchResults;
