import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import keycloakService from '../keycloakService';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
});

export default function Welcome() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <h1>Welcome to Admin Portal</h1>
        <p>
          {`If you don't have an Account `}
          <Link href="/sign-up" variant="body2">
            please register here
          </Link>
        </p>
        <Button
          onClick={() => {
            keycloakService.login();
          }}
          variant="contained"
          color="primary"
        >
          Go to Login
        </Button>
      </div>
    </div>
  );
}
