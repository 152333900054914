import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';

function TypingPapers() {
  const dispatch = useDispatch();
  const pendingExamPapers = useSelector((state) => state.examPaper.pendingExamPapers);
  useEffect(() => {
    const userDetails = keycloakService.getUserDetails();
    dispatch(fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' })); //no point fetchExamPapers take as status=''
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Typist Typing Papers
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table arial-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Typist Name</TableCell>
                    <TableCell>Subject Name</TableCell>
                    <TableCell>Subject Title</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingExamPapers.length >= 1 &&
                    pendingExamPapers.map(
                      (allPapers, index) =>
                        allPapers.typistStatus === 'TYPING' && (
                          <TableRow key={index}>
                            <TableCell>{allPapers.typistName}</TableCell>
                            <TableCell>{allPapers.subject}</TableCell>
                            <TableCell>{allPapers.title}</TableCell>
                            <TableCell>{allPapers.typistStatus}</TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypingPapers;
