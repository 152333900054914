import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const OverallStatCard = (props) => {
  const { statData } = props;
  return (
    <Card>
      <CardContent>
        <ul>
          <li>No of exams: {statData.totalExamAttempts || ''}</li>
          <li>No of questions: {statData.totalQuestionAttempts || ''}</li>
          <li>Questions answered correctly: {statData.totalCorrectQuestionAttempts || ''}</li>
          <li>Questions answered wrongly: {statData.totalIncorrectQuestionAttempts || ''}</li>
          <li>Pass percentage: {statData.totalExamAttemptPassPercentage || ''}</li>
        </ul>
      </CardContent>
    </Card>
  );
};

export default OverallStatCard;
