import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Remove } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';
import Questions from '../../components/ExamPaper/Questions';
import ExamPaperDetails from '../../components/ExamPaper/ExamPaperDetails';
import { FormControl, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sec, mins } from '../../utils/helper';
import {
  fetchExamPaperById,
  fetchExamQuestionById,
  createQuestion,
  updateQuestion,
  publishExamPaper
} from '../../actions/examPaperAction';
import Answers from '../../components/ExamPaper/Answer';
import Button from '@material-ui/core/Button';
import TeacherDashboardLayout from '../../layouts/DashboardLayout';
// import { testGroupQuestion } from '../../actions/examPaperAction';

const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

const answersInitialBlock = [
  {
    initialValue: [
      {
        type: 'paragraph',
        children: [{ text: '' }]
      }
    ]
  }
];
// const groupedQuestion = [
//   {
//     question: '',
//     startQuestion: '',
//     endQuestion: ''
//   }
// ];
class ExamPaperContainer extends Component {
  constructor() {
    super();
    this.state = {
      questionNo: 0,
      initialValue: questionInitialBlock,
      answers: answersInitialBlock,
      selectedAnswer: '',
      selectedExamPaperId: '',
      submitting: false,
      selectedQuestionId: '',
      error: '',
      expectedAvgTimeInSecond: 0,
      durationInSecond: 0,
      durationInMins: 0,
      grpQuestion: [],
      question: questionInitialBlock,
      startQuestion: 0,
      endQuestion: 0,
      noOfQuestions: 0
    };

    this.setAnswer = this.setAnswer.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSaveQuestionAndAnswers = this.handleSaveQuestionAndAnswers.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    // this.handleGroupQuestion = this.handleGroupQuestion.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleGrpShow = this.handleGrpShow.bind(this);
  }
  componentDidMount() {
    const { match, fetchExamPaperById } = this.props;
    const { examPaperId } = match.params;
    this.setState({ selectedExamPaperId: examPaperId });
    fetchExamPaperById(examPaperId);
  }

  componentDidUpdate(preProps) {
    const { examPaperDetails, questionDetails } = this.props;
    let { questionNo, initialValue, noOfQuestions, grpQuestion } = this.state;
    if (preProps.examPaperDetails !== examPaperDetails) {
      questionNo = Object.keys(examPaperDetails.questions).length + 1;
      grpQuestion = examPaperDetails.groupQuestions;
      console.log('grpQuestion', examPaperDetails);
      noOfQuestions = examPaperDetails.noOfQuestions;
      const answers = new Array(examPaperDetails.noOfAnswers).fill({
        initialValue: [{ type: 'paragraph', children: [{ text: '' }] }]
      });
      this.setState({ questionNo, answers, noOfQuestions, grpQuestion });
    }
    if (preProps.questionDetails !== questionDetails) {
      initialValue = JSON.parse(questionDetails.question);
      const questionAnswers = this.findAnswers(questionDetails.answers);
      const correctAnswer = this.findCorrectAnswer(questionDetails.answers);
      this.setState({ initialValue, answers: questionAnswers, selectedAnswer: correctAnswer });
    }
  }

  findAnswers(answers) {
    const answersData = [];
    if (answers && answers.length) {
      answers.forEach((data) => {
        const initialValue = JSON.parse(data.answer);
        answersData.push({ initialValue });
      });
    }
    return answersData;
  }

  findCorrectAnswer(answers) {
    let correctAnswer = '';
    if (answers && answers.length) {
      const answerIndex = answers.findIndex((answer) => answer.valid === true);
      correctAnswer = `answer-${answerIndex}`;
    }
    return correctAnswer;
  }

  setAnswer(answer, index) {
    let { answers } = this.state;
    answers = answers.map((item, i) => (i === index ? { initialValue: answer } : item));
    this.setState({ answers, error: '' });
  }
  addAnswer() {
    let { answers } = this.state;
    answers.push({
      initialValue: [
        {
          type: 'paragraph',
          children: [{ text: '' }]
        }
      ]
    });
    this.setState({ answers, error: '' });
  }
  removeAnswer() {
    let { answers } = this.state;
    answers.pop();
    this.setState({ answers, error: '' });
  }

  handleRadioChange(e) {
    this.setState({ selectedAnswer: e.target.value });
  }

  getAllQuestions() {
    const { answers, selectedAnswer } = this.state;
    const answersData = [];
    answers.forEach((answer, i) => {
      answersData.push({
        answer: JSON.stringify(answer.initialValue),
        valid: !!selectedAnswer.includes(i)
      });
    });
    return answersData;
  }

  validateQuestionAndAnswers(questionData) {
    const emptyBlock = JSON.stringify([{ type: 'paragraph', children: [{ text: '' }] }]);
    if (questionData.answers[0].answer === emptyBlock) {
      return false;
    }
    if (questionData.question === emptyBlock) {
      return false;
    }
    return true;
  }

  handleSaveQuestionAndAnswers(e) {
    e.preventDefault();
    const {
      selectedExamPaperId,
      questionNo,
      initialValue,
      selectedQuestionId,
      durationInSecond,
      durationInMins,
      question,
      endQuestion,
      startQuestion
    } = this.state;
    this.setState({ submitting: true });
    const { createQuestion, updateQuestion } = this.props;
    const totSecond = parseInt(durationInSecond) + parseInt(durationInMins);
    let group;
    if (startQuestion == 0 && endQuestion == 0) {
      group = null;
    } else {
      group = {
        grpQuestion: JSON.stringify(question),
        startQuestion: startQuestion,
        endQuestion: endQuestion
      };
    }

    const questionData = {
      examId: selectedExamPaperId,
      questionNo: questionNo,
      group: '',
      status: 'DRAFT',
      expectedAvgTimeInSecond: totSecond,
      question: JSON.stringify(initialValue),
      answers: this.getAllQuestions(),
      groupQuestion: group
    };
    const isValidQuestionAndAnswer = this.validateQuestionAndAnswers(questionData);
    if (!isValidQuestionAndAnswer) {
      this.setState({
        error: 'Please enter the question and answers correctly',
        submitting: false
      });
      return;
    }
    if (selectedQuestionId) {
      questionData.id = selectedQuestionId;
      updateQuestion(questionData, () => {
        this.setState({ selectedQuestionId: '' });
        window.location.reload();
      });
    } else {
      createQuestion(questionData, () => {
        window.location.reload();
      });
    }
  }
  handleChangeStart(e) {
    const { value } = e.target;
    this.setState({ startQuestion: value });
  }
  handleChangeEnd(e) {
    const { value } = e.target;
    this.setState({ endQuestion: value });
  }
  handleBack(e) {
    e.preventDefault();
    const { examPaperDetails, fetchExamQuestionById } = this.props;
    let { questionNo } = this.state;
    questionNo = questionNo - 1;
    const selectedQuestionId = examPaperDetails.questions
      ? examPaperDetails.questions[questionNo]
      : null;
    if (selectedQuestionId) {
      fetchExamQuestionById(selectedQuestionId);
      this.setState({ questionNo, selectedQuestionId });
    }
    this.handleGrpShow();
  }

  handleChangeDuration(e) {
    const { name, value } = e.target;
    if (name == 'durationInSecond') {
      this.setState({ durationInSecond: value });
    } else if (name == 'durationInMins') {
      this.setState({ durationInMins: value });
    }
  }
  handleGrpShow() {
    const { grpQuestion, questionNo } = this.state;
    console.log(questionNo, grpQuestion);
    if (grpQuestion != null) {
      for (let i = 0; i < grpQuestion.length; i++) {
        const item = grpQuestion[i];
        if (item.startQuestion <= questionNo - 1 && questionNo - 1 <= item.endQuestion) {
          this.setState({
            question: JSON.parse(item.grpQuestion),
            startQuestion: item.startQuestion,
            endQuestion: item.endQuestion
          });
          break;
        } else {
          this.setState({ question: questionInitialBlock });
        }
      }
    }
  }
  render() {
    const {
      initialValue,
      answers,
      questionNo,
      selectedAnswer,
      submitting,
      selectedExamPaperId,
      error,
      durationInSecond,
      durationInMins,
      noOfQuestions,
      startQuestion,
      endQuestion,
      question
    } = this.state;
    const { examPaperDetails } = this.props;
    const allQuestionsAdded = examPaperDetails.noOfQuestions + 1 === questionNo;
    if (allQuestionsAdded) {
      return <Redirect to={`/exam-paper/${selectedExamPaperId}/review`} />;
    }

    return (
      <Fragment>
        <TeacherDashboardLayout>
          <ExamPaperDetails
            {...this.state}
            examPaperDetails={examPaperDetails}
            allQuestionsAdded={allQuestionsAdded}
          />
          {!allQuestionsAdded ? (
            <>
              {error ? <p style={{ color: 'red' }}>{error}</p> : null}
              <h5>Add Grouped Question</h5>
              <Grid container space={3}>
                {/* <Grid item xs={3} sm={3}>
                  <h3>Mark as groupe Questions</h3>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Checkbox
                    // checked={allowpublic}
                    onChange={this.handleGroupQuestion}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="public"
                  />
                </Grid> */}
                <Grid item xs={8} sm={8}>
                  <Questions
                    input={question}
                    setInput={(val) => this.setState({ question: val })}
                  />
                </Grid>
                <Grid style={{ margin: '20px' }}>
                  <InputLabel id="select-duration-in-minus-label">start Question</InputLabel>
                  <Select
                    name="StartQuestion"
                    labelId="select-Start-grp"
                    id="select-Start-gr"
                    value={startQuestion}
                    onChange={this.handleChangeStart}
                  >
                    {/* {Array.from({ length: noOfQuestions - endQuestion }, (_, index) => (
                      <MenuItem key={index + 1} value={index + 1 + endQuestion}>
                        {index + 1 + endQuestion}
                      </MenuItem>
                    ))} */}
                    <MenuItem value={questionNo}>{questionNo}</MenuItem>
                  </Select>
                </Grid>
                <Grid style={{ margin: '20px' }}>
                  <InputLabel id="select-duration-in-minus-label">End Question</InputLabel>
                  <Select
                    name="EndQuestion"
                    labelId="select-End-grp"
                    id="select-End-grp"
                    value={endQuestion}
                    onChange={this.handleChangeEnd}
                  >
                    {Array.from({ length: noOfQuestions - questionNo }, (_, index) => (
                      <MenuItem key={index + 1} value={index + 1 + questionNo}>
                        {index + 1 + questionNo}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <h5>Add Questions</h5>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <Questions
                    input={initialValue}
                    setInput={(val) => this.setState({ initialValue: val })}
                  />
                </Grid>
                <div>
                  <lable>Expected Time (mm:ss):</lable>
                  <Grid item xs={12} sm={2}>
                    <FormControl
                      style={{
                        width: '100px'
                      }}
                    >
                      <InputLabel id="select-duration-in-minus-label">mm</InputLabel>
                      <Select
                        name="durationInMins"
                        labelId="select-duration-in-minus"
                        id="select-duration-in-minus"
                        value={durationInMins}
                        onChange={this.handleChangeDuration}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        {mins.map((info, i) => (
                          <MenuItem key={i} value={info}>
                            {info}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      style={{
                        width: '100px'
                      }}
                    >
                      <InputLabel id="select-duration-in-hrs-label">ss</InputLabel>
                      <Select
                        name="durationInSecond"
                        value={durationInSecond}
                        labelId="select-duration-in-sec"
                        id="select-duration-in-sec"
                        onChange={this.handleChangeDuration}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        {sec.map((info, i) => (
                          <MenuItem key={i} value={info}>
                            {info}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
              </Grid>
              <h5>Add Answers</h5>
              <Grid container spacing={3}>
                {answers.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <Grid item xs={12} sm={2}>
                        <p>Answer {i + 1}</p>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Answers
                          input={item.initialValue}
                          setInput={(val) => this.setAnswer(val, i)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Radio
                          checked={selectedAnswer === `answer-${i}`}
                          value={`answer-${i}`}
                          name="radio-button-demo"
                          onChange={this.handleRadioChange}
                          inputProps={{ 'aria-label': `${i}` }}
                        />
                        Marked As Correct
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div style={{ float: 'right' }}>
                    <Fab
                      aria-label="Add"
                      color="primary"
                      onClick={this.addAnswer}
                      style={{ margin: '3px' }}
                    >
                      <AddIcon />
                    </Fab>
                    <Fab
                      aria-label="remove"
                      color="primary"
                      onClick={this.removeAnswer}
                      style={{ margin: '3px' }}
                    >
                      <Remove />
                    </Fab>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ float: 'left' }}
                    variant="outlined"
                    color="primary"
                    disabled={questionNo <= 1}
                    onClick={this.handleBack}
                  >
                    <ArrowBackIcon /> Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleSaveQuestionAndAnswers}
                    style={{ float: 'right' }}
                    variant="outlined"
                    color="primary"
                    disabled={submitting}
                  >
                    Save question &amp; answers <ArrowForwardIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
        </TeacherDashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  // groupQuestion: state.examPaper.grpQuestion,
  questionDetails: state.examPaper.questionDetails
});

const mapDispatchToProps = {
  fetchExamQuestionById,
  fetchExamPaperById,
  createQuestion,
  updateQuestion,
  publishExamPaper
  // testGroupQuestion
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPaperContainer);
