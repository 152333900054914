import {
  FETCH_TYPIST_PAPERS_REQUEST,
  FETCH_ACCEPT_PAPERS_REQUEST,
  FETCH_HISTORY_PAPERS_REQUEST,
  FETCH_PENDING_REVIEW_REQUEST,
  FETCH_SUBSCRIBED_TYPIST,
  FETCH_PENDING_TYPIST_REQUEST,
  FETCH_CURRENT_TYPING_PAPER,
  FETCH_COMPLETED_TYPING_PAPER,
  FETCH_TECHER_REQUEST,
  FETCH_AVAILABLE_TYPIST
} from '../utils/type';

const initialState = {
  paperRequest: [],
  teacherRequest: [],
  acceptPaperRequest: [],
  historyPaperRequest: [],
  pendingReviewRequest: [],
  subscribedTypist: [],
  pendingTypistRequest: [],
  currentTypingPaper: [],
  completedPapers: [],
  availableTypist: []
};

const typistReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TYPIST_PAPERS_REQUEST:
      return {
        ...state,
        paperRequest: action.data
      };
    case FETCH_TECHER_REQUEST:
      return {
        ...state,
        teacherRequest: action.data
      };
    case FETCH_ACCEPT_PAPERS_REQUEST:
      return {
        ...state,
        acceptPaperRequest: action.data
      };
    case FETCH_HISTORY_PAPERS_REQUEST:
      return {
        ...state,
        historyPaperRequest: action.data
      };
    case FETCH_PENDING_REVIEW_REQUEST:
      return {
        ...state,
        pendingReviewRequest: action.data
      };
    case FETCH_SUBSCRIBED_TYPIST:
      return {
        ...state,
        subscribedTypist: action.data
      };
    case FETCH_PENDING_TYPIST_REQUEST:
      return {
        ...state,
        pendingTypistRequest: action.data
      };
    case FETCH_CURRENT_TYPING_PAPER:
      return {
        ...state,
        currentTypingPaper: action.data
      };
    case FETCH_COMPLETED_TYPING_PAPER:
      return {
        ...state,
        completedPapers: action.data
      };
    case FETCH_AVAILABLE_TYPIST:
      return {
        ...state,
        availableTypist: action.data
      };
    default:
      return state;
  }
};
export default typistReducer;
