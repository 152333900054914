import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamQuestionById } from '../../actions/examPaperAction';
import { Button, Card, CardContent, FormLabel, Grid } from '@material-ui/core';
import ReadOnly from '../../components/RichTextEditor/ReadOnly';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import DesignAnnotation from '../../components/DesignAnnotation';

function CorrectionQuestion(props) {
  const {
    noOfQuestion,
    currentQuestionId,
    currentQuestionNo,
    goToAnswersPage,
    handleNext,
    handlePrevious,
    attemptId,
    handleAnnotate,
    handleRemoveAnnotate,
    results,
    handleButtonClick,
    handleMark,
    marks
  } = props;
  const [initialValue, setInitialValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);
  const dispatch = useDispatch();
  const { questionDetails } = useSelector((state) => state.examPaper);
  useEffect(() => {
    if (currentQuestionId) {
      dispatch(fetchExamQuestionById(currentQuestionId));
    }
  }, [currentQuestionId]);
  useEffect(() => {
    if (questionDetails.question) {
      setInitialValue(JSON.parse(questionDetails.question));
    }
  }, [questionDetails]);

  let background = '';
  if (questionDetails.mark) {
    background = '#99cc99';
  } else {
    background = '#FFC2C7';
  }
  return (
    <Fragment>
      <Card style={{ backgroundColor: background }}>
        <CardContent style={{ margin: '20px' }}>
          <FormLabel component="legend">Question - {currentQuestionNo}</FormLabel>
          <ReadOnly initialValue={initialValue} />
          <div>
            {results[currentQuestionNo]?.essayAnswers && (
              <DesignAnnotation
                uploadFileURL={results[currentQuestionNo]?.essayAnswers}
                attemptId={attemptId}
                currentQuestionId={currentQuestionId}
                currentQuestionNo={currentQuestionNo}
                handleAnnotate={handleAnnotate}
                handleRemoveAnnotate={handleRemoveAnnotate}
                handleButtonClick={handleButtonClick}
                handleMark={handleMark}
                mark={marks}
              />
            )}
          </div>
        </CardContent>
      </Card>
      <Grid style={{ marginTop: 30, marginBottom: 30 }} container spacing={3}>
        <Grid item xs={6} sm={6}>
          <Button
            onClick={handlePrevious}
            disabled={currentQuestionNo == 1}
            style={{ float: 'left' }}
            variant="outlined"
            color="primary"
          >
            <ArrowBack />
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Button
            onClick={handleNext}
            disabled={noOfQuestion == currentQuestionNo}
            style={{ float: 'right' }}
            variant="outlined"
            color="primary"
          >
            <ArrowForward />
            Next
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            onClick={goToAnswersPage}
            style={{ float: 'left' }}
            variant="outlined"
            color="primary"
          >
            Go to Results
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default CorrectionQuestion;
