import React, { Component, createRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DashboardLayout from '../../layouts/DashboardLayout';
import CSVFileReader from '../../components/CSVReader';
import { connect } from 'react-redux';
import {
  fetchValidatedUserList,
  fetchAllTeachers,
  fetchTeacherPapers,
  subscribePapers,
  clearValidUserList
} from '../../actions/examPaperAction';
import StudentDetailsTable from '../../components/StudentDetailsTable';
import NotFoundStudentList from '../../components/NotFundStudentList';
import { validateEmail } from '../../utils/helper';
import { Card, CardContent } from '@material-ui/core';
class ExamPaperSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCsvFileUploaded: false,
      validUserDetails: [],
      unfoundEmails: [],
      errorMsg: '',
      teachers: [],
      examPapers: [],
      selectedTeacherId: '',
      selectedPaperId: '',
      isSubscribeClicked: false,
      successMsg: '',
      batch: ''
    };
    this.handleCSVUpload = this.handleCSVUpload.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleUpdateDetails = this.handleUpdateDetails.bind(this);
    this.deleteUnFoundEmail = this.deleteUnFoundEmail.bind(this);
    this.handleTeacherChange = this.handleTeacherChange.bind(this);
    this.handlePaperChange = this.handlePaperChange.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handleClearCsv = this.handleClearCsv.bind(this);
    this.csvFormRef = createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { fetchAllTeachers } = this.props;
    fetchAllTeachers();
  }

  componentDidUpdate(preProps) {
    const { validatedUserData } = this.props;
    if (preProps.validatedUserData !== validatedUserData) {
      if (validatedUserData.USERS_FOUND) {
        this.setState({ validUserDetails: validatedUserData.USERS_FOUND });
      }
      if (validatedUserData.USERS_NOT_FOUND) {
        this.setState({ unfoundEmails: validatedUserData.USERS_NOT_FOUND });
      }
    }
  }

  handleCSVUpload(data) {
    const { fetchValidatedUserList } = this.props;
    this.setState({ isCsvFileUploaded: true });
    if (data && data.length) {
      const userEmails = this.getEmailList(data);
      fetchValidatedUserList(userEmails);
    }
  }

  getEmailList(users) {
    return users.map((user) => user.email);
  }

  handleChangeEmail(e, index) {
    const { unfoundEmails } = this.state;
    unfoundEmails[index] = e.target.value;
    this.setState({ unfoundEmails, errorMsg: '' });
  }

  deleteUnFoundEmail(email) {
    const { unfoundEmails } = this.state;
    const newEmails = unfoundEmails.filter((unfoundEmail) => unfoundEmail !== email);
    this.setState({ unfoundEmails: newEmails });
  }

  validateUpdatedEmails(newEmails, validUserDetails) {
    let isValid = true;
    let message = '';
    if (newEmails.length) {
      newEmails.forEach((newEmail) => {
        if (!validateEmail(newEmail)) {
          isValid = false;
          message = `${newEmail} is not valid. please enter valid email`;
        } else {
          const duplicateUser = validUserDetails.find(
            (existingUser) => existingUser.email === newEmail
          );
          if (duplicateUser) {
            isValid = false;
            message = `${duplicateUser.email} already added in the list please check the emails again`;
          }
        }
      });
    }
    return {
      isValid,
      message
    };
  }

  getUpdatedEmails(newEmails, validUserDetails) {
    let oldEmails = [];
    if (validUserDetails.length) {
      oldEmails = validUserDetails.map((validUserDetail) => validUserDetail.email);
    }
    oldEmails = [...oldEmails, ...newEmails];
    return oldEmails;
  }

  handleUpdateDetails(e) {
    e.preventDefault();
    const { unfoundEmails, validUserDetails } = this.state;
    const { fetchValidatedUserList } = this.props;
    const { isValid, message } = this.validateUpdatedEmails(unfoundEmails, validUserDetails);
    if (!isValid) {
      this.setState({ errorMsg: message });
      return;
    }
    const updatedEmailList = this.getUpdatedEmails(unfoundEmails, validUserDetails);
    fetchValidatedUserList(updatedEmailList);
  }

  handleTeacherChange(e, teacher) {
    e.preventDefault();
    if (!teacher || !teacher.id) return;
    const { fetchTeacherPapers } = this.props;
    this.setState({ selectedTeacherId: teacher.id }, () => {
      fetchTeacherPapers(teacher.id, 'PUBLISHED');
    });
  }

  handlePaperChange(e, paper) {
    e.preventDefault();
    if (!paper || !paper.id) return;
    this.setState({ selectedPaperId: paper.id });
  }

  clearSuccessMsg() {
    setTimeout(() => {
      this.setState({ successMsg: '' });
    }, 3000);
  }

  handleSubscribe(e) {
    e.preventDefault();
    const { subscribePapers } = this.props;
    const { selectedPaperId, validUserDetails, batch } = this.state;
    this.setState({ isSubscribeClicked: true });
    const subscribeData = {
      examId: selectedPaperId,
      studentIds: validUserDetails.map((user) => user.id),
      batch
    };
    subscribePapers(subscribeData, () => {
      this.setState(
        {
          successMsg: 'paper subscribed successfully',
          selectedTeacherId: '',
          selectedPaperId: '',
          isSubscribeClicked: false,
          batch: ''
        },
        () => {
          this.handleClearCsv(e);
          this.clearSuccessMsg();
        }
      );
    });
  }

  handleClearCsv(e) {
    const { clearValidUserList } = this.props;
    e.preventDefault();
    this.csvFormRef.current.reset();
    clearValidUserList();
    this.setState({
      isCsvFileUploaded: false,
      validUserDetails: [],
      unfoundEmails: []
    });
  }

  handleInputChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  render() {
    const { validatedUserData, teachers, teacherExamPapers } = this.props;
    const {
      isCsvFileUploaded,
      errorMsg,
      unfoundEmails,
      selectedPaperId,
      validUserDetails,
      isSubscribeClicked,
      successMsg,
      selectedTeacherId,
      batch
    } = this.state;

    return (
      <DashboardLayout>
        <Container className="main-container">
          <Card>
            <CardContent>
              <h3>Paper subscribe</h3>
              {successMsg ? <p style={{ color: 'green', fontSize: '20px' }}>{successMsg}</p> : null}
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={6}>
                    <Autocomplete
                      id="teacher-id"
                      options={teachers}
                      getOptionLabel={(option) => option.fullName}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select teacher" variant="outlined" />
                      )}
                      onChange={this.handleTeacherChange}
                      value={teachers.find((teacher) => teacher.id === selectedTeacherId)}
                      key={selectedTeacherId}
                    />
                  </Grid>
                  <Grid item xs={3} sm={6}>
                    <Autocomplete
                      id="exam-id"
                      options={teacherExamPapers}
                      getOptionLabel={(option) => option.title || ''}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select exam paper" variant="outlined" />
                      )}
                      onChange={this.handlePaperChange}
                      value={teacherExamPapers.find((paper) => paper.id === selectedPaperId)}
                      key={selectedPaperId}
                    />
                  </Grid>
                  <Grid item xs={3} sm={6}>
                    <TextField
                      id="batch"
                      fullWidth
                      label="Batch"
                      variant="outlined"
                      onChange={this.handleInputChange}
                      name="batch"
                      value={batch}
                    />
                  </Grid>
                </Grid>
              </div>
              <CSVFileReader
                handleFileUpload={this.handleCSVUpload}
                isFileUploaded={isCsvFileUploaded}
                handleClearCsv={this.handleClearCsv}
                csvFormRef={this.csvFormRef}
              />
              <StudentDetailsTable userData={validatedUserData} />
              <NotFoundStudentList
                handleUpdateDetails={this.handleUpdateDetails}
                handleChangeEmail={this.handleChangeEmail}
                handleDelete={this.deleteUnFoundEmail}
                unfoundEmails={unfoundEmails}
                errorMsg={errorMsg}
              />
              <Button
                disabled={
                  isSubscribeClicked ||
                  !selectedPaperId ||
                  !(validUserDetails.length !== 0 && unfoundEmails.length === 0)
                }
                onClick={this.handleSubscribe}
                variant="contained"
                color="primary"
              >
                Subscribe
              </Button>
            </CardContent>
          </Card>
        </Container>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  validatedUserData: state.examPaper.validatedUserData,
  teachers: state.examPaper.teachers,
  teacherExamPapers: state.examPaper.teacherExamPapers
});

const mapDispatchToProps = {
  fetchValidatedUserList,
  fetchAllTeachers,
  fetchTeacherPapers,
  subscribePapers,
  clearValidUserList
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPaperSubscribe);
