import React, { Component, Fragment } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import Addselector from '../../components/AddSelector/Addselector';
import { Button, Card, CardContent, Container, Grid } from '@material-ui/core';
import { cascadeFilter } from '../../actions/filterAction';
import { connect } from 'react-redux';
import {
  addNewSelector,
  updateNewSelector,
  DeleteNewSelector,
  addNewTeacherSelector
} from '../../actions/filterAction'; //
import UpdateSelector from '../../components/AddSelector/UpdateSelector';
import DeleteSelector from '../../components/AddSelector/DeleteSelector';
import { fetchAllTeachers } from '../../actions/examPaperAction';

const SELECTOR = {
  language: '',
  grade: '',
  subject: '',
  teacher: ''
};
class HandleSelector extends Component {
  constructor() {
    super();
    this.state = {
      selector: SELECTOR,
      Language: '',
      Grade: '',
      Subject: '',
      Teacher: '',
      image: '',
      newSubject: '',
      newTeacher: '',
      parent: '',
      parentId: '',
      navigate: 'add',
      subjectVal: '',
      teacher: '',
      preId: '',
      preParent: '',
      selectedTeacherId: ''
    };
    this.handleChangeClick = this.handleChangeClick.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.addSubject = this.addSubject.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.updateSubject = this.updateSubject.bind(this);
    this.deleteSubject = this.deleteSubject.bind(this);
    this.addhandleTeacher = this.addhandleTeacher.bind(this);
    this.updatehandleTeacher = this.updatehandleTeacher.bind(this);
    this.handleTeacherChange = this.handleTeacherChange.bind(this);
    this.deleteTeacher = this.deleteTeacher.bind(this);
  }
  componentDidMount() {
    const { cascadeFilter, fetchAllTeachers } = this.props;
    cascadeFilter('root');
    fetchAllTeachers();
  }
  componentDidUpdate(preProps) {
    const { cascadeFilterResult } = this.props;
    if (preProps.cascadeFilterResult !== cascadeFilterResult) {
      this.setState({ allFilter: cascadeFilterResult });
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Language') {
        this.setState({ Language: cascadeFilterResult });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Grade') {
        this.setState({ Grade: cascadeFilterResult });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Subject') {
        this.setState({ Subject: cascadeFilterResult });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Teacher') {
        this.setState({ Teacher: cascadeFilterResult });
      }
    }
  }
  handleButton(navigate) {
    this.setState({
      navigate: navigate,
      Grade: '',
      Subject: '',
      Teacher: '',
      image: '',
      newSubject: '',
      newTeacher: '',
      parent: '',
      parentId: '',
      subjectVal: '',
      teacher: '',
      preId: '',
      preParent: ''
    });
  }
  handleChangeClick(e) {
    const { cascadeFilter } = this.props;
    const { selector } = this.state;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const keys = selectedOption.dataset.key;
    const image = selectedOption.dataset.img;
    const { name, value } = e.target;
    selector[name] = keys;
    if (name == 'subject') {
      this.setState({
        image: image,
        subjectVal: value,
        newSubject: value
      });
    }
    if (name == 'teacher') {
      this.setState({ image: image });
    }
    this.setState({ selector });
    cascadeFilter(keys);
  }
  handleSubjectChange(e) {
    const { value } = e.target;
    this.setState({ newSubject: value });
  }

  addSubject(image) {
    const { newSubject, selector } = this.state;
    const { addNewSelector } = this.props;
    this.setState({ image: image });
    const addSelector = {
      name: newSubject,
      group: 'Subject',
      parentId: selector.grade,
      image: image
    };
    addNewSelector(addSelector);
  }
  addhandleTeacher() {
    const { selectedTeacherId, selector } = this.state;
    const { addNewTeacherSelector } = this.props;
    const addSelector = {
      parentId: selector.subject,
      teacherId: selectedTeacherId
    };
    addNewTeacherSelector(addSelector);
  }
  updateSubject(img) {
    const { newSubject, image, selector } = this.state;
    const { updateNewSelector } = this.props;
    const addSelector = {
      id: selector.subject,
      name: newSubject,
      group: 'Subject',
      parentId: selector.grade,
      image: img ? img : image
    };
    updateNewSelector(addSelector);
  }
  updatehandleTeacher() {
    const { newTeacher, preId, preParent, selectedTeacherId } = this.state;
    const { updateNewSelector } = this.props;
    const addSelector = {
      id: selectedTeacherId,
      name: newTeacher,
      group: 'Teacher',
      parent: preParent,
      parentId: preId
    };
    updateNewSelector(addSelector);
  }
  deleteSubject() {
    const { DeleteNewSelector } = this.props;
    const { selector } = this.state;
    DeleteNewSelector(selector.subject);
  }
  handleTeacherChange(e, teacher) {
    e.preventDefault();
    if (!teacher || !teacher.id) return;
    this.setState({ selectedTeacherId: teacher.id, newTeacher: teacher.fullName });
  }
  deleteTeacher() {
    const { DeleteNewSelector } = this.props;
    const { selector } = this.state;
    DeleteNewSelector(selector.teacher);
  }
  render() {
    const {
      Language,
      Subject,
      Grade,
      navigate,
      newSubject,
      Teacher,
      selectedTeacherId,
      selector,
      subjectVal
    } = this.state;
    const { teachers } = this.props;
    return (
      <Fragment>
        <DashboardLayout>
          <Container
            className="main-container"
            style={{ marginTop: '150px', justifyContent: 'center', textAlign: 'center' }}
          >
            <Card>
              <CardContent style={{ backgroundColor: '#DCD2CC' }}>
                <h1>Selectors</h1>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <Button variant="contained" onClick={() => this.handleButton('add')}>
                      Add New Seletor
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button variant="contained" onClick={() => this.handleButton('update')}>
                      Update Seletor
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button variant="contained" onClick={() => this.handleButton('delete')}>
                      Delete Seletor
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {navigate == 'add' ? (
              <Addselector
                Language={Language}
                Subject={Subject}
                Grade={Grade}
                teachers={teachers}
                selector={selector}
                selectedTeacherId={selectedTeacherId}
                handleChangeClick={this.handleChangeClick}
                handleSubjectChange={this.handleSubjectChange}
                handleTeacherChange={this.handleTeacherChange}
                addhandleTeacher={this.addhandleTeacher}
                addSubject={this.addSubject}
              />
            ) : navigate == 'update' ? (
              <UpdateSelector
                Language={Language}
                Subject={Subject}
                Grade={Grade}
                subject={newSubject}
                selector={selector}
                subjectVal={subjectVal}
                handleChangeClick={this.handleChangeClick}
                handleSubjectChange={this.handleSubjectChange}
                updateSubject={this.updateSubject}
              />
            ) : (
              <DeleteSelector
                Language={Language}
                Subject={Subject}
                Grade={Grade}
                Teacher={Teacher}
                selector={selector}
                handleChangeClick={this.handleChangeClick}
                handleSubjectChange={this.handleSubjectChange}
                deleteSubject={this.deleteSubject}
                deleteTeacher={this.deleteTeacher}
              />
            )}
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  cascadeFilterResult: state.filter.cascadeFilterResult,
  teachers: state.examPaper.teachers
});
const mapDispatchToProps = {
  cascadeFilter,
  addNewSelector,
  updateNewSelector,
  DeleteNewSelector,
  addNewTeacherSelector,
  fetchAllTeachers
};
export default connect(mapStateToProps, mapDispatchToProps)(HandleSelector);
