import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Science from '../PaperHistory/images/science-default.png';

export default function ResultsCard({ publishedExamPapers }) {
  return (
    <Grid container spacing={3}>
      {publishedExamPapers.map((publishedExamPaper) => (
        <Grid item xs={12} sm={12} key={publishedExamPaper.id}>
          <Card className="d-flex paper-card">
            <CardMedia
              image={Science}
              title="Live from space album cover"
              style={{ width: '150px' }}
            />
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {publishedExamPaper.title || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Subject - {publishedExamPaper.subject || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Category - {publishedExamPaper.category || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Grade - {publishedExamPaper.grade || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Category - {moment(publishedExamPaper.scheduledTime).format('DD/MM/YYYY')}
              </Typography>
            </CardContent>
            <CardActions>
              <div className="d-flex flex-flow-col align-items-center">
                <a className="button" href={`published-exam-paper/${publishedExamPaper.id}/view`}>
                  View
                </a>
              </div>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
