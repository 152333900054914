import { FETCH_AVAILABLE_FAQ } from '../utils/type';

const initialState = {
  availableFAQ: []
};

export const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVAILABLE_FAQ:
      return {
        ...state,
        availableFAQ: action.data
      };
    default:
      return state;
  }
};
