import React, { Component, Fragment } from 'react';
import FaqHandler from '../../components/Faq/FaqHandler';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchAvailableFaq, deleteFaq, updateFaq, patchFaq } from '../../actions/faqAction';
import FaqAdding from '../../components/Faq/FaqAdding';

const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableFAQ: [],
      selectedFaqId: '',
      selectedFaqQuestion: '',
      selectedFaqAnswer: '',
      newAnswer: questionInitialBlock,
      newQuestion: ''
    };
    this.handleFaqChange = this.handleFaqChange.bind(this);
    this.handleEditSetData = this.handleEditSetData.bind(this);
    this.handleFaqQuesChange = this.handleFaqQuesChange.bind(this);
    this.handleDeleteFaq = this.handleDeleteFaq.bind(this);
    this.handleUpdateChanges = this.handleUpdateChanges.bind(this);
    this.addNewAnswer = this.addNewAnswer.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.handleAddQuestionAnswer = this.handleAddQuestionAnswer.bind(this);
  }
  componentDidMount() {
    const { fetchAvailableFaq } = this.props;
    fetchAvailableFaq();
  }
  componentDidUpdate(preProps) {
    const { availableFaq } = this.props;
    if (preProps.availableFaq != availableFaq) {
      this.setState({ availableFAQ: availableFaq });
    }
  }
  handleFaqChange(val) {
    console.log('val', val);
    const changedFaq = JSON.stringify(val);
    this.setState({ selectedFaqAnswer: changedFaq });
  }
  handleEditSetData(faq) {
    console.log('faq', faq);
    this.setState({
      selectedFaqId: faq.id,
      selectedFaqQuestion: faq.question,
      selectedFaqAnswer: faq.answer
    });
  }
  handleFaqQuesChange(e) {
    const { value } = e.target;
    this.setState({ selectedFaqQuestion: value });
  }
  handleDeleteFaq(id) {
    const { deleteFaq } = this.props;
    const { fetchAvailableFaq } = this.props;
    deleteFaq(id, () => fetchAvailableFaq());
  }
  handleUpdateChanges() {
    const { updateFaq } = this.props;
    const { selectedFaqId, selectedFaqQuestion, selectedFaqAnswer } = this.state;
    const faq = {
      id: selectedFaqId,
      question: selectedFaqQuestion,
      answer: selectedFaqAnswer
    };
    updateFaq(faq, selectedFaqId, () => fetchAvailableFaq());
  }
  addNewAnswer(val) {
    this.setState({ newAnswer: val });
  }
  addNewQuestion(e) {
    const { value } = e.target;
    this.setState({ newQuestion: value });
  }
  handleAddQuestionAnswer() {
    const { patchFaq } = this.props;
    const { newQuestion, newAnswer } = this.state;
    const faq = {
      question: newQuestion,
      answer: JSON.stringify(newAnswer)
    };

    patchFaq(faq, () => {
      this.setState({ newQuestion: '', newAnswer: questionInitialBlock }, fetchAvailableFaq());
    });
  }
  render() {
    const {
      availableFAQ,
      selectedFaqId,
      selectedFaqQuestion,
      selectedFaqAnswer,
      newAnswer,
      newQuestion
    } = this.state;
    return (
      <Fragment>
        <DashboardLayout>
          <Container className="main-container">
            <FaqAdding
              answer={newAnswer}
              question={newQuestion}
              addNewAnswer={this.addNewAnswer}
              addNewQuestion={this.addNewQuestion}
              handleAddQuestionAnswer={this.handleAddQuestionAnswer}
            />
            <FaqHandler
              faqDetail={availableFAQ}
              selectedFaqId={selectedFaqId}
              selectedFaqQuestion={selectedFaqQuestion}
              selectedFaqAnswer={selectedFaqAnswer}
              handleFaqChange={this.handleFaqChange}
              handleEditSetData={this.handleEditSetData}
              handleFaqQuesChange={this.handleFaqQuesChange}
              handleDeleteFaq={this.handleDeleteFaq}
              handleUpdateChanges={this.handleUpdateChanges}
            />
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  availableFaq: state.faq.availableFAQ
});
const mapDispatchToProps = {
  fetchAvailableFaq,
  deleteFaq,
  updateFaq,
  patchFaq
};
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
