import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchCorrectorTeacherRequest } from '../../actions/tutorAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  correctorAcceptTeacherRequest,
  correctorRejectTeacherRequest
} from '../../actions/tutorAction';

function CorrectorTeacherRequest() {
  const dispatch = useDispatch();
  const teacherRequest = useSelector((state) => state.essayPaperRequest.correctorTeacherRequest);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const handleClick = (click, id) => {
    if (click == 'accept') {
      dispatch(correctorAcceptTeacherRequest(id));
    } else if (click == 'reject') {
      dispatch(correctorRejectTeacherRequest(id));
      window.location.reload();
    }
    setTriggerUpdate(!triggerUpdate);
  };
  useEffect(() => {
    dispatch(fetchCorrectorTeacherRequest());
  }, [triggerUpdate]);
  const requestList = (request, index) => {
    return (
      <TableRow key={index}>
        <TableCell>
          {request.firstName} {request.lastName}
        </TableCell>
        <TableCell>{request.email}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => handleClick('accept', request.id)}>
            Accept
          </Button>
          <Button variant="contained" onClick={() => handleClick('reject', request.id)}>
            Reject
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Corrector Request Pending Page
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Teacher Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Teacher Email</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teacherRequest &&
                    teacherRequest.length >= 1 &&
                    teacherRequest.map((request, index) => requestList(request, index))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CorrectorTeacherRequest;
