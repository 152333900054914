import axios from 'axios';
import { getValidToken } from './filterAction';
import { API_URL, authHeader } from '../utils/helper';

export const promoCodeGenerating = (promoCode, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/points/promo_code-initialize`, promoCode, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        onSuccess('There is an error when create promoCode');
        console.error('there is an error when create promoCode', e);
      });
  };
};
export const pointsTopupViamail = (studentEmail, promoAmount, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/points/email-points-topup?email=${studentEmail}&amount=${promoAmount}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('There is an error when you topup via mail', e);
      });
  };
};
