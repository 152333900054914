import {
  FETCH_SUBSCRIPTION_RESULTS_TEST,
  FETCH_AVAILABLE_CORRECTOR,
  FETCH_CORRECTOR_PAPERS_REQUEST,
  FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST,
  FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT,
  FETCH_EXAMPAPER_BY_ATTEMPT_ID,
  FETCH_CORRECTION_HISTORY_PAPERS,
  FETCH_CORRECTOR_TEACHER_REQUEST,
  FETCH_CORRECTOR_PENDING_REQUEST,
  FETCH_CORRECTOR_SUBSCRIBE_REQUEST,
  FETCH_PENDING_PAPERS_BY_CORRECTOR,
  FETCH_ACCEPTED_PAPERS_BY_CORRECTOR
} from '../utils/type';
const initialState = {
  essaypaperRequest: [],
  allPapersforSubject: [],
  acceptEssayPaperRequest: [],
  subscriptionResult: [],
  availableCorrector: [],
  examPaperByAttemptId: [],
  correctionHistoryPapers: [],
  correctorTeacherRequest: [],
  getRequestPendingCorrector: [],
  getSubscribedCorrector: [],
  getPendingPaperByCorrector: [],
  getAcceptedPapersByCorrector: []
};

const correctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CORRECTOR_PAPERS_REQUEST:
      return {
        ...state,
        essaypaperRequest: action.data
      };
    case FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST:
      return {
        ...state,
        acceptEssayPaperRequest: action.data
      };
    case FETCH_SUBSCRIPTION_RESULTS_TEST:
      return {
        ...state,
        subscriptionResult: action.data
      };
    case FETCH_AVAILABLE_CORRECTOR:
      return {
        ...state,
        availableCorrector: action.data
      };
    case FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT:
      return {
        ...state,
        allPapersforSubject: action.data
      };
    case FETCH_EXAMPAPER_BY_ATTEMPT_ID:
      return {
        ...state,
        examPaperByAttemptId: action.data
      };
    case FETCH_CORRECTION_HISTORY_PAPERS:
      return {
        ...state,
        correctionHistoryPapers: action.data
      };
    case FETCH_CORRECTOR_TEACHER_REQUEST:
      return {
        ...state,
        correctorTeacherRequest: action.data
      };
    case FETCH_CORRECTOR_PENDING_REQUEST:
      return {
        ...state,
        getRequestPendingCorrector: action.data
      };
    case FETCH_CORRECTOR_SUBSCRIBE_REQUEST:
      return {
        ...state,
        getSubscribedCorrector: action.data
      };
    case FETCH_PENDING_PAPERS_BY_CORRECTOR:
      return {
        ...state,
        getPendingPaperByCorrector: action.data
      };
    case FETCH_ACCEPTED_PAPERS_BY_CORRECTOR:
      return {
        ...state,
        getAcceptedPapersByCorrector: action.data
      };
    default:
      return state;
  }
};
export default correctorReducer;
