import React, { useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { pointsTopupViamail } from '../../actions/pointAction';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
function pointsTopupViaEmail() {
  const [promoAmount, setPromoAmount] = useState(0);
  const [studentEmail, setStudentEmail] = useState('');
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(true);
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == 'promoAmount') {
      setPromoAmount(value);
    } else if (name == 'studentEmail') {
      setStudentEmail(value);
    }
    if (promoAmount != 0 && studentEmail != '') {
      setdisable(false);
    }
  };
  const handlePromoCode = () => {
    dispatch(
      pointsTopupViamail(studentEmail, promoAmount, (res) => {
        setMessage(
          res,
          setTimeout(() => {
            setMessage('');
          }, 3000)
        );
      })
    );
  };
  return (
    <DashboardLayout>
      <Card
        style={{
          border: '1px solid black',
          margin: '10% 25% 0 25%'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <h2
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              Email Base Points Topup For Student
            </h2>
            <Divider />
            {message != '' && <p style={{ color: 'green' }}>{message}</p>}
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>Promo Amount</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoAmount"
                  required
                  onChange={handleChange}
                  placeholder="Enter Promo Amount Hear"
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>Student Email</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="studentEmail"
                  autoComplete="email"
                  required
                  onChange={handleChange}
                  placeholder="Enter Promo code Here"
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  onClick={() => handlePromoCode()}
                  disabled={disable}
                  variant="outlined"
                  color="primary"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default pointsTopupViaEmail;
