import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  a: {
    display: 'flex',
    cursor: 'pointer'
  }
});

export default function BasicTable(props) {
  const classes = useStyles();
  const { historyYearData, handleBack } = props;

  return (
    <div>
      <a className={classes.a} onClick={handleBack}>
        <ArrowBackIcon />
        &nbsp;Back
      </a>
      <div>
        <ul>
          <li>
            Paper: {historyYearData && historyYearData[0] ? historyYearData[0].paperTitle : null}
          </li>
        </ul>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>January</TableCell>
              <TableCell>Febuary</TableCell>
              <TableCell>March</TableCell>
              <TableCell>April</TableCell>
              <TableCell>May</TableCell>
              <TableCell>June</TableCell>
              <TableCell>July</TableCell>
              <TableCell>Augues</TableCell>
              <TableCell>Spetember</TableCell>
              <TableCell>Octobar</TableCell>
              <TableCell>November</TableCell>
              <TableCell>December</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                No. Papers
              </TableCell>
              {historyYearData &&
                historyYearData.length &&
                historyYearData[0].stats.map((row) => (
                  <TableCell key={row} component="th" scope="row">
                    {row.count}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
