import {
  FETCH_TYPIST_PAPERS_REQUEST,
  FETCH_ACCEPT_PAPERS_REQUEST,
  FETCH_HISTORY_PAPERS_REQUEST,
  FETCH_PENDING_REVIEW_REQUEST,
  FETCH_SUBSCRIBED_TYPIST,
  FETCH_PENDING_TYPIST_REQUEST,
  FETCH_CURRENT_TYPING_PAPER,
  FETCH_COMPLETED_TYPING_PAPER,
  FETCH_TECHER_REQUEST,
  FETCH_AVAILABLE_TYPIST
} from '../utils/type';
import axios from 'axios';
import { getValidToken } from './filterAction';
import { API_URL, authHeader } from '../utils/helper';

export const fetchExamPaperRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/paper-request`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TYPIST_PAPERS_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const acceptPaperReaquest = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/paper-request/accept?examId=${examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const rejectPaperRequest = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/paper-request/rejected?examId=${examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const fetchAcceptPaperRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/paper-request/in-progress`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ACCEPT_PAPERS_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchHistoryTypingPaper = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/paper-request/history`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_HISTORY_PAPERS_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchPendingReviewRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/paper-typing/in-review`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PENDING_REVIEW_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const patchTypistSubmit = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/paper-typing/submit?examId=${examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const acceptTypingPaperReaquest = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/paper-typing/accept?examId=${examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const rejectTypingPaperRequest = (examId, reviewMessage, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/typist/paper-typing/reject?examId=${examId}&reviewMessage=${reviewMessage}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const reAssignTypist = (examId, typistId, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/typist/paper-request/reassign?examId=${examId}&typistId=${typistId}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const typistSubscriptionRequest = (typistId, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/typist-request?typistId=${typistId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const fetchSubscribedTypist = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/subscribed-typists`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_SUBSCRIBED_TYPIST,
          data: res.data
        });
      })
      .catch((e) => {
        console.log('there is something wrong', e);
      });
  };
};

export const fetchPendingTypistRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/pending-typists`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PENDING_TYPIST_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const fetchCurrentTypingPapers = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/current-tasks-by-teacher-id`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_CURRENT_TYPING_PAPER,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const fetchCompletedTypingPapers = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/completed-tasks-by-teacher-id`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_COMPLETED_TYPING_PAPER,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const fetchTeacherRequest = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/typist-request`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TECHER_REQUEST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const acceptTeacherReaquest = (teacherId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/typist/typist-request/confirm?teacherId=${teacherId}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
export const rejectTeacherRequest = (teacherId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/typist/typist-request/reject?teacherId=${teacherId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const fetchAvailableTypist = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/typist/available-typists`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_AVAILABLE_TYPIST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};
