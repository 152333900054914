import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  //Box,
  //Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  //Link,
  //MenuItem,
  //OutlinedInput,
  //Select,
  Table,
  TableCell,
  //TableBody,
  //TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
//import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  }
}));

function CorrectorSubscription(props) {
  const {
    correctors,
    selectCorrector,
    handleCorrectorChange,
    subscribeCorrector,
    subscribedCorrector,
    pendingCorrectorRequest
    // currentTypingPapers,
    // completedTypingPapers
  } = props;
  const classes = useStyles();
  //   console.log('currentTypingPapers', currentTypingPapers);
  //   console.log('completedTypingPapers', completedTypingPapers);
  return (
    <DashboardLayout>
      <h1>CorrectorSubscription is Developping</h1>
      <Card>
        <CardContent>
          <h3>Corrector Subscriptions</h3>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Search Correctors</InputLabel>
            </Grid>
            {/* <Grid item xs={6} sm={3}>
              <OutlinedInput />
            </Grid> */}
            <Grid item xs={6} sm={4}>
              <Autocomplete
                id="corrector-id"
                options={correctors}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField {...params} label="Select Corrector" variant="outlined" />
                )}
                //value={selectTypist}
                onChange={handleCorrectorChange}
              />
            </Grid>
            {/*<Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ width: '100%' }}
              >
                <MenuItem>john</MenuItem>
                <MenuItem>Roy</MenuItem>
                <MenuItem>Ravi</MenuItem>
                <MenuItem>Suman</MenuItem>
                </Select>*/}
            <Grid item xs={6} sm={4}>
              {selectCorrector && (
                <div>
                  <ul>
                    <li>First Name :- {selectCorrector.firstName}</li>
                    <li>Last Name :- {selectCorrector.lastName}</li>
                    <li>User Name :- {selectCorrector.userName}</li>
                    <li>Email :- {selectCorrector.email}</li>
                    <li>Photo :- {selectCorrector.profileImage}</li>
                  </ul>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" onClick={subscribeCorrector}>
              Subscribe
            </Button>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <h3>Subscribed Corrector</h3>
              <Card>
                <CardContent>
                  {subscribedCorrector.length >= 1 &&
                    subscribedCorrector.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <strong>{index + 1}. </strong>
                          <span>
                            {item.firstName} {item.lastName}
                          </span>
                        </li>
                      </ul>
                    ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6}>
              <h3>Pending Corrector</h3>
              <Card>
                <CardContent>
                  {pendingCorrectorRequest.length >= 1 &&
                    pendingCorrectorRequest.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <strong>{index + 1}. </strong>
                          <span>
                            {item.firstName} {item.lastName}
                          </span>
                          {/* <Link style={{ paddingLeft: '50px' }}>Remainder</Link> */}
                        </li>
                      </ul>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid>
            <h3>Current works</h3>
            <Card>
              <CardContent>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Create Date</TableCell>
                      <TableCell>Need to Finish Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Name of Typist</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    {currentTypingPapers.length >= 1 &&
                      currentTypingPapers.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.subject}</TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>23/3/2022</TableCell>
                          <TableCell>29/3/2022</TableCell>
                          <TableCell>{item.typistStatus}</TableCell>
                          <TableCell>
                            {item.typistName} <Button variant="contained">Remove</Button>
                            <Button variant="contained">Change</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody> */}
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <h3>History</h3>
            <Card>
              <CardContent>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Create Date</TableCell>
                      <TableCell>Name of Typist</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    {completedTypingPapers.length >= 1 &&
                      completedTypingPapers.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.subject}</TableCell>
                          <TableCell>23/3/2022</TableCell>
                          <TableCell>{item.typistName}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody> */}
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CorrectorSubscription;
