import axios from 'axios';
import { API_URL, authHeader } from '../utils/helper';
import { getValidToken } from './filterAction';
import {
  GET_EXAM_PAPER_HISTORY,
  GET_EXAM_PAPER_MONTH_BREAKDOWN,
  GET_EXAM_PAPER_YEAR_HISTORY
} from '../utils/type';

export const getExamPapersHistory = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/report/exam-paper/stat`, authHeader(token))
      .then((res) => {
        dispatch({
          type: GET_EXAM_PAPER_HISTORY,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getExamPaperYearHistory = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/report/exam-paper/stat/year`, authHeader(token))
      .then((res) => {
        dispatch({
          type: GET_EXAM_PAPER_YEAR_HISTORY,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getExamPaperMonthsHistory = (paperDetails) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(
        `${API_URL}/report/exam-paper/stat/month?examId=${paperDetails.examId}&year=${paperDetails.year}`,
        authHeader(token)
      )
      .then((res) => {
        dispatch({
          type: GET_EXAM_PAPER_MONTH_BREAKDOWN,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
