import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    width: '100%',
    maxWidth: '120px',
    justifyContent: 'center'
  },
  listItemText: {
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  answerIndicator: {
    display: 'flex',
    flexDirection: 'row',
    margin: 3
  },
  homeBtn: {
    border: '1px solid black',
    float: 'left',
    padding: 10,
    color: 'black',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  examPaperCard: {
    marginTop: 100
  }
}));

const getStatusColor = (correctAnswer) => {
  if (correctAnswer) {
    return '#59981A';
  }
  return '#FFAEBC';
};

function Annotation(props) {
  const { results, goToEssayQuestion } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.examPaperCard}>
      <Grid item xs={12} sm={12}>
        <p className={classes.answerIndicator}>
          <CheckBoxOutlineBlankIcon
            style={{ color: '#59981A', background: '#59981A', borderRadius: '5px' }}
            className="mr-1"
          />{' '}
          Corrected
        </p>
        <p className={classes.answerIndicator}>
          <CheckBoxOutlineBlankIcon
            style={{ color: '#FFAEBC', background: '#FFAEBC', borderRadius: '5px' }}
            className="mr-1"
          />{' '}
          Not Corrected Papers
        </p>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Card>
          <List className={classes.root}>
            {results &&
              Object.values(results).map((value, i) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem
                    className={classes.listItem}
                    key={value.questionId}
                    role={undefined}
                    dense
                    button
                    onClick={(e) => goToEssayQuestion(e, value, i + 1)}
                  >
                    <ListItemIcon>
                      <div
                        className={classes.listItemText}
                        style={{
                          border: `2px solid ${getStatusColor(value.correct)}`,
                          borderRadius: '10px',
                          backgroundColor: `${getStatusColor(value.correct)}`,
                          display: 'block'
                        }}
                      >
                        <strong
                          id={labelId}
                          style={{
                            color: 'black',
                            fontSize: '30',
                            fontWeight: '800',
                            paddingLeft: '20px',
                            paddingRight: '20px'
                          }}
                        >
                          Q{i + 1}
                        </strong>
                      </div>
                    </ListItemIcon>
                  </ListItem>
                );
              })}
          </List>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Annotation;
