import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  fetchTeacherPapers,
  fetchOverAllExamPapersStat,
  fetchExamPaperStatByPaperId
} from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';
import TableView from '../../components/Reports/TableView';
import OverallStatCard from '../../components/Reports/OverallStatCard';
import OverallStatPieChart from '../../components/Reports/OverallStatPieChart';
import ExamPaperStatLineChart from '../../components/Reports/ExamPaperStatLineChart';
class Reports extends Component {
  constructor() {
    super();
    this.state = {
      selectedPaperId: '',
      series: [],
      options: {
        chart: {
          type: 'line',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Overall',
          align: 'left'
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          title: {
            text: 'Question No'
          },
          categories: []
        },
        yaxis: {
          title: {
            text: 'time(s)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {}
      },
      overallExamStat: {
        series: [],
        options: {
          chart: {
            width: 380,
            type: 'pie'
          },
          labels: ['Pass', 'Fail'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]
        },
        isDataLoaded: false
      },
      hideOverallStat: false,
      isExamPaperStatFetching: false,
      isLineChartReadyLoad: false
    };
    this.handlePaperChange = this.handlePaperChange.bind(this);
  }

  componentDidMount() {
    const { overallExamStat } = this.state;
    const { fetchTeacherPapers, fetchOverAllExamPapersStat } = this.props;
    const { getUserDetails } = keycloakService;
    fetchTeacherPapers(getUserDetails().sub, 'PUBLISHED');
    fetchOverAllExamPapersStat((data) => {
      overallExamStat.series.push(
        data.totalExamAttemptPassPercentage,
        data.totalExamAttemptFailPercentage
      );
      overallExamStat.isDataLoaded = true;
      this.setState({
        overallExamStat
      });
    });
  }

  handlePaperChange(e, paper) {
    e.preventDefault();
    let { options, series } = this.state;
    const { fetchExamPaperStatByPaperId } = this.props;
    if (!paper || !paper.id) {
      this.setState({ selectedPaperId: '', hideOverallStat: false });
    } else {
      this.setState({
        selectedPaperId: paper.id,
        isExamPaperStatFetching: true,
        hideOverallStat: true
      });
      fetchExamPaperStatByPaperId(paper.id, (data) => {
        const questions = this.filterDataByProps(data.questionAttemptStats, 'questionNo');
        const avgTimeTakenList = this.filterDataByProps(
          data.questionAttemptStats,
          'avgTimeSpentInSecond'
        );
        const maxTimeTakenList = this.filterDataByProps(
          data.questionAttemptStats,
          'maxTimeSpentInSecond'
        );
        const minTimeTakenList = this.filterDataByProps(
          data.questionAttemptStats,
          'minTimeSpentInSecond'
        );
        series = [];
        options.xaxis.categories = [];
        series.push(
          {
            name: 'Max Time taken',
            data: maxTimeTakenList
          },
          {
            name: 'Avg Time taken',
            data: avgTimeTakenList
          },
          {
            name: 'Min Time taken',
            data: minTimeTakenList
          }
        );
        options.xaxis.categories = questions;
        this.setState({
          isExamPaperStatFetching: false,
          options,
          series,
          isLineChartReadyLoad: true
        });
      });
    }
  }

  filterDataByProps(data = [], prop) {
    return data.map((a) => a[prop]);
  }

  render() {
    const { teacherExamPapers, overallExamPapersStat, examPaperStat } = this.props;
    const {
      selectedPaperId,
      options,
      series,
      isExamPaperStatFetching,
      overallExamStat,
      hideOverallStat,
      isLineChartReadyLoad
    } = this.state;
    return (
      <Fragment>
        <DashboardLayout>
          <Container className="main-container">
            <div>
              <h2>Reports</h2>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="exam-id"
                    options={teacherExamPapers}
                    getOptionLabel={(option) => option.title || ''}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select exam paper" variant="outlined" />
                    )}
                    onChange={this.handlePaperChange}
                    value={teacherExamPapers.find((paper) => paper.id === selectedPaperId)}
                    key={selectedPaperId}
                  />
                </Grid>
                <Grid item xs={6} sm={6}></Grid>
                {!hideOverallStat ? (
                  <Fragment>
                    <Grid item xs={6} sm={6}>
                      <OverallStatPieChart
                        options={overallExamStat.options}
                        series={overallExamStat.series}
                        isDataLoaded={overallExamStat.isDataLoaded}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <OverallStatCard statData={overallExamPapersStat} />
                    </Grid>
                  </Fragment>
                ) : null}
                {selectedPaperId && !isExamPaperStatFetching ? (
                  <Fragment>
                    <Grid item xs={12} sm={12}>
                      <TableView data={examPaperStat.questionAttemptStats} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ExamPaperStatLineChart
                        options={options}
                        series={series}
                        isDataLoaded={isLineChartReadyLoad}
                      />
                    </Grid>
                  </Fragment>
                ) : null}
              </Grid>
            </div>
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  teacherExamPapers: state.examPaper.teacherExamPapers,
  overallExamPapersStat: state.examPaper.overallExamPapersStat,
  examPaperStat: state.examPaper.examPaperStat
});

const mapDispatchToProps = {
  fetchTeacherPapers,
  fetchOverAllExamPapersStat,
  fetchExamPaperStatByPaperId
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
