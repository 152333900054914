import Keycloak from 'keycloak-js';
import { KEYCLAOK_REALM, KEYCLOAK_CLIENT_ID, KEYCLOAK_SERVER_BASE_URL } from './utils/helper';

const config = {
  realm: KEYCLAOK_REALM,
  url: KEYCLOAK_SERVER_BASE_URL,
  clientId: KEYCLOAK_CLIENT_ID
};

const initOptions = {
  onLoad: 'check-sso'
};

const keycloak = Keycloak(config);

const initKeyCloak = async (renderAppCallBack) => {
  return keycloak
    .init(initOptions)
    .then(() => {
      renderAppCallBack();
    })
    .catch((e) => {
      console.error('there is an error', e);
    });
};

const getToken = () => keycloak.token;
const isLoggedIn = () => !!keycloak.token;
const login = keycloak.login;
const logout = keycloak.logout;
const register = keycloak.register;
const getUserName = () => (keycloak.tokenParsed ? keycloak.tokenParsed.preferred_username : '');
const getUserDetails = () => (keycloak.tokenParsed ? keycloak.tokenParsed : {});
const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const updateToken = async () => {
  try {
    const refreshed = await keycloak.updateToken(20);
    return refreshed;
  } catch (e) {
    return false;
  }
};

const roles = keycloak.realmAccess ? keycloak.realmAccess.roles : [];
const keycloakService = {
  initKeyCloak,
  getToken,
  isLoggedIn,
  login,
  logout,
  getUserName,
  register,
  hasRole,
  getUserDetails,
  updateToken,
  roles
};
export default keycloakService;
