export const FECTH_FILTER_SEARCH_DATA = 'FECTH_FILTER_SEARCH_DATA';
export const CLEAR_FILTER_SEARCH = 'CLEAR_FILTER_SEARCH';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const FETCH_ROOT_FILTER_DATA = 'FETCH_ROOT_FILTER_DATA';
export const FETCH_FILTER_DATA_BY_TITLE = 'FETCH_FILTER_DATA_BY_TITLE';
export const REMOVE_FILTERS_DATA = 'REMOVE_FILTERS_DATA';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const FETCH_EXAMS_PAPERS = 'FETCH_EXAMS_PAPERS';
export const FETCH_EXAM_PAPER_BY_ID = 'FETCH_EXAM_PAPER_BY_ID';
export const FETCH_EXAM_QUESTION_BY_ID = 'FETCH_EXAM_QUESTION_BY_ID';
export const FETCH_ALL_TEACHERS = 'FETCH_ALL_TEACHERS';
export const FETCH_ALL_TYPISTS = 'FETCH_ALL_TYPISTS';
export const FETCH_TEACHER_EXAM_PAPERS = 'FETCH_TEACHER_EXAM_PAPERS';
export const FETCH_SUBSCRIPTION_RESULTS = 'FETCH_SUBSCRIPTION_RESULTS';
export const FETCH_ALL_QUESTIONS = 'FETCH_ALL_QUESTIONS';
export const FETCH_EXAM_PAPERS = 'FETCH_EXAM_PAPERS';
export const FETCH_VALIDATED_USER_LIST = 'FETCH_VALIDATED_USER_LIST';
export const SET_FILTER_INDEX = 'SET_FILTER_INDEX';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const FETCH_TEACHER_PAPERS_BY_EXAM_STATUS = 'FETCH_TEACHER_PAPERS_BY_EXAM_STATUS';
export const FECTH_PUBLISHED_PAPERS = 'FECTH_PUBLISHED_PAPERS';

export const GET_EXAM_ATTEMPT = 'GET_EXAM_ATTEMPT';
export const GET_ATTEMPT_STATUS = 'GET_ATTEMPT_STATUS';
export const GET_EXAM_ATTEMPT_RESULT = 'GET_EXAM_ATTEMPT_RESULT';

export const SET_TIMER_DURATION = 'SET_TIMER_DURATION';

export const GET_EXAM_PAPER_HISTORY = 'GET_EXAM_PAPER_HISTORY';
export const GET_EXAM_PAPER_YEAR_HISTORY = 'GET_EXAM_PAPER_YEAR_HISTORY';
export const GET_EXAM_PAPER_MONTH_BREAKDOWN = 'GET_EXAM_PAPER_MONTH_BREAKDOWN';

export const CLEAR_VALID_USER_LIST = 'CLEAR_VALID_USER_LIST';

export const FETCH_SELECTOR_ALL = 'FETCH_SELECTOR_ALL';
export const CLEAR_EXAM_PAPER_DETAILS = 'CLEAR_EXAM_PAPER_DETAILS';

export const FETCH_OVERALL_EXAM_PAPERS_STAT = 'FETCH_OVERALL_EXAM_PAPERS_STAT';
export const FETCH_EXAM_PAPER_STAT_BY_PAPER_ID = 'FETCH_EXAM_PAPER_STAT_BY_PAPER_ID';
export const FETCH_EXAM_PAPER_STAT_BY_BATCH = 'FETCH_EXAM_PAPER_STAT_BY_BATCH';

export const FETCH_TEACHER_SUBSCRIPTIONS = 'FETCH_TEACHER_SUBSCRIPTIONS';
export const FETCH_TEACHER_DASHBOARD = 'FETCH_TEACHER_DASHBOARD';

export const FETCH_TYPIST_PAPERS_REQUEST = 'FETCH_TYPIST_PAPERS_REQUEST';
export const FETCH_ACCEPT_PAPERS_REQUEST = 'FETCH_ACCEPT_PAPERS_REQUEST';
export const FETCH_HISTORY_PAPERS_REQUEST = 'FETCH_HISTORY_PAPERS_REQUEST';
export const FETCH_PENDING_REVIEW_REQUEST = 'FETCH_PENDING_REVIEW_REQUEST';

export const FETCH_CASCADING_FILTER_RESULT = 'FETCH_CASCADING_FILTER_RESULT';

export const FETCH_SUBSCRIBED_TYPIST = 'FETCH_SUBSCRIBED_TYPIST';
export const FETCH_PENDING_TYPIST_REQUEST = 'FETCH_PENDING_TYPIST_REQUEST';

export const FETCH_CURRENT_TYPING_PAPER = 'FETCH_CURRENT_TYPING_PAPER';
export const FETCH_COMPLETED_TYPING_PAPER = 'FETCH_CURRENT_TYPING_PAPER';

export const FETCH_TECHER_REQUEST = 'FETCH_TECHER_REQUEST';
export const FETCH_AVAILABLE_TYPIST = 'FETCH_AVAILABLE_TYPIST';

export const FETCH_PROFILE_DETAILS = 'FETCH_PROFILE_DETAILS';

export const FETCH_TEACHER_FINAL_PRICE = 'FETCH_TEACHER_FINAL_PRICE';

export const FETCH_SUBSCRIPTION_RESULTS_TEST = 'FETCH_SUBSCRIPTION_RESULTS_TEST';

export const FETCH_AVAILABLE_CORRECTOR = 'FETCH_AVAILABLE_CORRECTOR';
export const FETCH_CORRECTOR_PENDING_REQUEST = 'FETCH_CORRECTOR_PENDING_REQUEST';
export const FETCH_CORRECTOR_SUBSCRIBE_REQUEST = 'FETCH_CORRECTOR_SUBSCRIBE_REQUEST';
export const FETCH_PENDING_PAPERS_BY_CORRECTOR = 'FETCH_PENDING_PAPERS_BY_CORRECTOR';
export const FETCH_ACCEPTED_PAPERS_BY_CORRECTOR = 'FETCH_ACCEPTED_PAPERS_BY_CORRECTOR';

export const FETCH_CORRECTOR_PAPERS_REQUEST = 'FETCH_CORRECTOR_PAPERS_REQUEST';
export const FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST = 'FETCH_ACCEPT_CORRECTOR_PAPERS_REQUEST';
export const FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT = 'FETCH_ALL_PAPERS_FOR_SELECT_SUBJECT';
export const FETCH_EXAMPAPER_BY_ATTEMPT_ID = 'FETCH_EXAMPAPER_BY_ATTEMPT_ID';
export const FETCH_CORRECTION_HISTORY_PAPERS = 'FETCH_CORRECTION_HISTORY_PAPERS';
export const FETCH_CORRECTOR_TEACHER_REQUEST = 'FETCH_CORRECTOR_TEACHER_REQUEST';

export const FETCH_AVAILABLE_FAQ = 'FETCH_AVAILABLE_FAQ';

export const TEST_GROUP_QUESTION = 'TEST_GROUP_QUESTION';
