import {
  FETCH_TEACHER_SUBSCRIPTIONS,
  FETCH_TEACHER_DASHBOARD,
  FETCH_PROFILE_DETAILS
} from '../utils/type';

const initialState = {
  subscriptions: [],
  dashboardInfo: {},
  userProfile: ''
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEACHER_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.data
      };
    case FETCH_TEACHER_DASHBOARD:
      return {
        ...state,
        dashboardInfo: action.data
      };
    case FETCH_PROFILE_DETAILS:
      return {
        ...state,
        userProfile: action.data
      };
    default:
      return state;
  }
};

export default userReducer;
