import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';
import Questions from '../../components/ExamPaper/Questions';
import ExamPaperDetails from '../../components/ExamPaper/ExamPaperDetails';
import { FormControl, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sec, mins } from '../../utils/helper';
import {
  fetchExamPaperById,
  fetchExamQuestionById,
  createQuestion,
  updateQuestion,
  publishExamPaper
} from '../../actions/examPaperAction';
import Button from '@material-ui/core/Button';
import TeacherDashboardLayout from '../../layouts/DashboardLayout';

const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

class ExamPaperContainer extends Component {
  constructor() {
    super();
    this.state = {
      questionNo: 0,
      initialValue: questionInitialBlock,
      selectedExamPaperId: '',
      submitting: false,
      selectedQuestionId: '',
      error: '',
      expectedAvgTimeInSecond: 0,
      durationInSecond: 0,
      durationInMins: 0
    };

    this.handleSaveQuestionAndAnswers = this.handleSaveQuestionAndAnswers.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
  }
  componentDidMount() {
    const { match, fetchExamPaperById } = this.props;
    const { examPaperId } = match.params;
    this.setState({ selectedExamPaperId: examPaperId });
    fetchExamPaperById(examPaperId);
  }

  componentDidUpdate(preProps) {
    const { examPaperDetails, questionDetails } = this.props;
    let { questionNo, initialValue } = this.state;
    if (preProps.examPaperDetails !== examPaperDetails) {
      questionNo = Object.keys(examPaperDetails.questions).length + 1;
      const answers = new Array(examPaperDetails.noOfAnswers).fill({
        initialValue: [{ type: 'paragraph', children: [{ text: '' }] }]
      });
      this.setState({ questionNo, answers });
    }
    if (preProps.questionDetails !== questionDetails) {
      initialValue = JSON.parse(questionDetails.question);
      this.setState({ initialValue });
    }
  }

  findAnswers(answers) {
    const answersData = [];
    if (answers && answers.length) {
      answers.forEach((data) => {
        const initialValue = JSON.parse(data.answer);
        answersData.push({ initialValue });
      });
    }
    return answersData;
  }

  validateQuestionAndAnswers(questionData) {
    const emptyBlock = JSON.stringify([{ type: 'paragraph', children: [{ text: '' }] }]);
    if (questionData.question === emptyBlock) {
      return false;
    }
    return true;
  }

  handleSaveQuestionAndAnswers(e) {
    e.preventDefault();
    const {
      selectedExamPaperId,
      questionNo,
      initialValue,
      selectedQuestionId,
      durationInSecond,
      durationInMins
    } = this.state;
    this.setState({ submitting: true });
    const { createQuestion, updateQuestion } = this.props;
    const totSecond = parseInt(durationInSecond) + parseInt(durationInMins);
    const questionData = {
      examId: selectedExamPaperId,
      questionNo: questionNo,
      group: '',
      status: 'DRAFT',
      expectedAvgTimeInSecond: totSecond,
      question: JSON.stringify(initialValue)
    };
    const isValidQuestionAndAnswer = this.validateQuestionAndAnswers(questionData);
    if (!isValidQuestionAndAnswer) {
      this.setState({
        error: 'Please enter the question  correctly',
        submitting: false
      });
      return;
    }
    if (selectedQuestionId) {
      questionData.id = selectedQuestionId;
      updateQuestion(questionData, () => {
        this.setState({ selectedQuestionId: '' });
        window.location.reload();
      });
    } else {
      createQuestion(questionData, () => {
        window.location.reload();
      });
    }
  }

  handleBack(e) {
    e.preventDefault();
    const { examPaperDetails, fetchExamQuestionById } = this.props;
    let { questionNo } = this.state;
    questionNo = questionNo - 1;
    const selectedQuestionId = examPaperDetails.questions
      ? examPaperDetails.questions[questionNo]
      : null;
    if (selectedQuestionId) {
      fetchExamQuestionById(selectedQuestionId);
      this.setState({ questionNo, selectedQuestionId });
    }
  }

  handleChangeDuration(e) {
    const { name, value } = e.target;
    if (name == 'durationInSecond') {
      this.setState({ durationInSecond: value });
    } else if (name == 'durationInMins') {
      this.setState({ durationInMins: value });
    }
  }

  render() {
    const {
      initialValue,
      questionNo,
      submitting,
      selectedExamPaperId,
      error,
      durationInSecond,
      durationInMins
    } = this.state;
    const { examPaperDetails } = this.props;
    const allQuestionsAdded = examPaperDetails.noOfQuestions + 1 === questionNo;
    if (allQuestionsAdded) {
      return <Redirect to={`/exam-paper/${selectedExamPaperId}/review`} />;
    }

    return (
      <Fragment>
        <TeacherDashboardLayout>
          <ExamPaperDetails
            {...this.state}
            examPaperDetails={examPaperDetails}
            allQuestionsAdded={allQuestionsAdded}
          />
          {!allQuestionsAdded ? (
            <>
              {error ? <p style={{ color: 'red' }}>{error}</p> : null}
              <h5>Add Questions</h5>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <Questions
                    input={initialValue}
                    setInput={(val) => this.setState({ initialValue: val })}
                  />
                </Grid>
                <div>
                  <lable>Expected Time (mm:ss):</lable>
                  <Grid item xs={12} sm={2}>
                    <FormControl
                      style={{
                        width: '100px'
                      }}
                    >
                      <InputLabel id="select-duration-in-minus-label">mm</InputLabel>
                      <Select
                        name="durationInMins"
                        labelId="select-duration-in-minus"
                        id="select-duration-in-minus"
                        value={durationInMins}
                        onChange={this.handleChangeDuration}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        {mins.map((info, i) => (
                          <MenuItem key={i} value={info}>
                            {info}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      style={{
                        width: '100px'
                      }}
                    >
                      <InputLabel id="select-duration-in-hrs-label">ss</InputLabel>
                      <Select
                        name="durationInSecond"
                        value={durationInSecond}
                        labelId="select-duration-in-sec"
                        id="select-duration-in-sec"
                        onChange={this.handleChangeDuration}
                      >
                        <MenuItem value={0}>00</MenuItem>
                        {sec.map((info, i) => (
                          <MenuItem key={i} value={info}>
                            {info}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ float: 'left' }}
                    variant="outlined"
                    color="primary"
                    disabled={questionNo <= 1}
                    onClick={this.handleBack}
                  >
                    <ArrowBackIcon /> Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleSaveQuestionAndAnswers}
                    style={{ float: 'right' }}
                    variant="outlined"
                    color="primary"
                    disabled={submitting}
                  >
                    Save question &amp; answers <ArrowForwardIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
        </TeacherDashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  questionDetails: state.examPaper.questionDetails
});

const mapDispatchToProps = {
  fetchExamQuestionById,
  fetchExamPaperById,
  createQuestion,
  updateQuestion,
  publishExamPaper
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPaperContainer);
