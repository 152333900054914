import React, { useEffect, useState } from 'react';
import Annotation from 'react-image-annotation';
import { RectangleSelector } from 'react-image-annotation/lib/selectors';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { Button, Grid, InputLabel, OutlinedInput, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { uploadImageAnnotation } from '../../actions/tutorAction';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyle = makeStyles(() => ({
  imageNav: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  marks: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  }
}));
function DesignAnnotation(props) {
  const {
    uploadFileURL,
    attemptId,
    currentQuestionId,
    handleAnnotate,
    handleRemoveAnnotate,
    handleButtonClick,
    handleMark,
    mark
  } = props;
  const dispatch = useDispatch();
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});
  const [currentImageNo, setCurrentQuestionNo] = useState(0);
  const [img, setImg] = useState('');
  const noOfImages = uploadFileURL.length;
  const questionId = currentQuestionId;
  const classes = useStyle();
  useEffect(() => {
    setCurrentQuestionNo(0);
  }, [currentQuestionId]);
  useEffect(() => {
    setImg(uploadFileURL[currentImageNo]?.answerFile || '');
    setAnnotations(uploadFileURL[currentImageNo]?.fileAnnotations || []);
  }, [uploadFileURL]);
  const onChange = (annotation) => {
    setAnnotation(annotation);
  };
  const onSubmit = (annotation) => {
    const { geometry, data } = annotation;

    setAnnotation({});
    setAnnotations((prevAnnotations) => [
      ...prevAnnotations,
      {
        geometry,
        data: {
          ...data
          //id: Math.random()
        }
      }
    ]);
    const annotationData = {
      answerFile: img,
      fileAnnotation: {
        geometry,
        data: {
          ...data
        }
      }
    };
    dispatch(uploadImageAnnotation(questionId, attemptId, annotationData));
  };
  const nextImage = () => {
    handleAnnotate();
    setImg(uploadFileURL[currentImageNo + 1].answerFile);
    setCurrentQuestionNo(currentImageNo + 1);
    setAnnotation({});
  };
  const previousImage = () => {
    handleAnnotate();
    setCurrentQuestionNo(currentImageNo - 1);
    setAnnotation({});
  };
  const handleDeleteAnnotation = (annotation) => {
    const annotationData = {
      answerFile: img,
      fileAnnotation: annotation
    };
    handleRemoveAnnotate(questionId, annotationData);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} sm={1} className={classes.imageNav}>
        <Button
          disabled={currentImageNo == 0}
          onClick={previousImage}
          variant="outlined"
          color="primary"
        >
          <NavigateBefore />
        </Button>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Annotation
          src={img}
          alt="Two pebbles anthropomorphized holding hands"
          annotations={annotations}
          type={RectangleSelector.type}
          value={annotation}
          onChange={onChange}
          onSubmit={onSubmit}
          allowTouch
        />
        {annotations.map((annotation, index) => (
          <div
            key={index}
            style={{
              margin: '2px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <InputLabel
              style={{ marginRight: '2px', alignItems: 'center', alignContent: 'center' }}
            >
              {index + 1}.{annotation.data.text}
            </InputLabel>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleDeleteAnnotation(annotation, index)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ))}
      </Grid>
      <Grid item xs={1} sm={1} className={classes.imageNav}>
        <Button
          disabled={noOfImages == currentImageNo + 1 || img.length <= 0}
          onClick={nextImage}
          variant="outlined"
          color="primary"
        >
          <NavigateNext />
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        className={classes.marks}
        style={{ display: noOfImages == currentImageNo + 1 ? 'flex' : 'none' }}
      >
        <InputLabel>Marks: </InputLabel>
        <OutlinedInput name="marks" value={mark} onChange={handleMark} />
        <Button variant="outlined" color="primary" onClick={() => handleButtonClick()}>
          Add Marks
        </Button>
      </Grid>
    </Grid>
  );
}

export default DesignAnnotation;
