import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { Button, Fade, Menu, MenuItem } from '@material-ui/core';
// import { isTypist } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import { ROLE } from '../../utils/helper';

const navItems1 = [
  {
    href: '/history',
    title: 'History'
  },
  {
    href: '/settlement-details',
    title: 'Settlement details'
  },
  {
    href: '/subscribe-paper',
    title: 'Add subscription'
  },
  {
    href: '/reports',
    title: 'Reports'
  },
  {
    href: '/batch-results',
    title: 'Batch Results'
  },
  {
    href: '/subscriptions',
    title: 'Subscriptions'
  }
];
const addSubject = [
  {
    href: '/admin-add-subject',
    title: 'Add Subject'
  },
  {
    href: '/faq',
    title: 'Faq'
  },
  {
    href: '/promo-code',
    title: 'Promo Code Subscription'
  },
  {
    href: '/points-subscription-student',
    title: 'Student Points Adding'
  }
];
const navItems2 = [
  {
    href: '/typist-history',
    title: 'History'
  },
  {
    href: '/typist-teachers-requests',
    title: 'Teachers Requests'
  },
  {
    href: '/typist-papers-requests',
    title: 'Papers Requests'
  },
  {
    href: '/typist-current-work',
    title: 'Current Work'
  },
  {
    href: '/in-review',
    title: 'Pending Review'
  }
];
const navItems3 = [
  {
    href: '/tuter-history',
    title: 'History'
  },
  {
    href: '/tuter-teachers-requests',
    title: 'Teachers Requests'
  },
  {
    href: '/tuter-papers-requests',
    title: 'Papers Requests'
  },
  {
    href: '/tuter-current-work',
    title: 'Current Work'
  },
  {
    href: '/tuter-alerts',
    title: 'Alerts'
  }
];
const SideBar = () => {
  const [papersMenuAnchorEl, setPapersMenuAnchorEl] = useState(null);
  const [typistMenuAnchorEl, setTypistMenuAnchorEl] = useState(null);
  const [correctorMenuAnchorEl, setCorrectorMenuAnchorEl] = useState(null);

  const openPapersMenu = Boolean(papersMenuAnchorEl);
  const openTypistMenu = Boolean(typistMenuAnchorEl);
  const openCorrectorMenu = Boolean(correctorMenuAnchorEl);

  const handlePapersClick = (event) => {
    setPapersMenuAnchorEl(event.currentTarget);
  };

  const handleTypistClick = (event) => {
    setTypistMenuAnchorEl(event.currentTarget);
  };
  const handleCorrectorClick = (event) => {
    setCorrectorMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPapersMenuAnchorEl(null);
    setTypistMenuAnchorEl(null);
    setCorrectorMenuAnchorEl(null);
  };

  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);
  const hasTypistRole = keycloakService.hasRole([ROLE.Typist]);
  const hasTuterRole = keycloakService.hasRole([ROLE.Tutor]);
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  return (
    <div>
      <div className="menu-item-area">
        {hasTeacherRole &&
          navItems1.map((navItem1, i) => (
            <Link key={`navItem1-${i}`} href={navItem1.href}>
              {navItem1.title}
            </Link>
          ))}
        {hasAdminRole &&
          addSubject.map((navItem1, i) => (
            <Link key={`navItem1-${i}`} href={navItem1.href}>
              {navItem1.title}
            </Link>
          ))}
        {hasTeacherRole && (
          <>
            <Button
              id="papers-menu-button"
              aria-controls={openPapersMenu ? 'papers-menu' : undefined}
              aria-haspopup="true"
              onClick={handlePapersClick}
            >
              {' '}
              Papers
            </Button>
            <Menu
              id="papers-menu"
              MenuListProps={{
                'aria-labelledby': 'papers-menu-button'
              }}
              anchorEl={papersMenuAnchorEl}
              open={openPapersMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              style={{ marginTop: '50px' }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/pending-exam-papers" underline="none">
                  Pending exam papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/published-exam-papers" underline="none">
                  Published exam papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/create-paper" underline="none">
                  Paper creation and typist selection
                </Link>
              </MenuItem>
            </Menu>
            <Button
              id="typist-menu-button"
              aria-controls={openTypistMenu ? 'typist-menu' : undefined}
              aria-haspopup="true"
              onClick={handleTypistClick}
            >
              {' '}
              Typist
            </Button>
            <Menu
              id="typist-menu"
              MenuListProps={{
                'aria-labelledby': 'typist-menu-button'
              }}
              anchorEl={typistMenuAnchorEl}
              open={openTypistMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              style={{ marginTop: '50px' }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/typist" underline="none">
                  Typist Subscriptions
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/typing-papers" underline="none">
                  Typing Papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/teacher-in-review" underline="none">
                  Review Request Papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/typing-rejected" underline="none">
                  Typing Request Reject Papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/typing-completed" underline="none">
                  Typing Completed Papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/typing-pending" underline="none">
                  Typing Request Pending Papers
                </Link>
              </MenuItem>
            </Menu>
            <Button
              id="corrector-menu-button"
              aria-controls={openCorrectorMenu ? 'corrector-menu' : undefined}
              aria-haspopup="true"
              onClick={handleCorrectorClick}
            >
              {' '}
              Corrector
            </Button>
            <Menu
              id="corrector-menu"
              MenuListProps={{
                'aria-labelledby': 'corrector-menu-button'
              }}
              anchorEl={correctorMenuAnchorEl}
              open={openCorrectorMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              style={{ marginTop: '50px' }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/correction" underline="none">
                  Corrector Subscriptions
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/corrector-rejected" underline="none">
                  Corrector Rejected Request
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/corrector-accept-papers" underline="none">
                  Corrector Correcting Papers
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/corrector-requestpending-papers" underline="none">
                  Corrector Pending Request Papers
                </Link>
              </MenuItem>
            </Menu>
          </>
        )}
        {/* {hasAdminRole && <Link href="teacher_assignment/admin">Teacher_Assign</Link>} */}
        {hasTypistRole &&
          navItems2.map((navItem2, i) => (
            <Link key={`navItem2-${i}`} href={navItem2.href}>
              {navItem2.title}
            </Link>
          ))}
        {hasTuterRole &&
          navItems3.map((navItem3, i) => (
            <Link key={`navItem3-${i}`} href={navItem3.href}>
              {navItem3.title}
            </Link>
          ))}
        {/* {console.log(keycloakService.hasRole([ROLE.Teacher]))}
        {console.log(isTypist)} */}
        {keycloakService.hasRole([ROLE.Teacher]) == true ? 'Teacher' : ''}
        {keycloakService.hasRole([ROLE.Typist]) == true ? 'I am a Typist' : ''}
        {keycloakService.hasRole([ROLE.Tutor]) == true ? 'I am a Tuter' : ''}
      </div>
    </div>
  );
};

export default SideBar;
