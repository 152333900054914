import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NameSelector from '../Selector/NameSelector';
import { uploadFiles } from '../../actions/examPaperAction';
import Compressor from 'compressorjs';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  }
}));
function Addselector(props) {
  const {
    Language,
    Subject,
    Grade,
    handleChangeClick,
    handleSubjectChange,
    addSubject,
    handleTeacherChange,
    addhandleTeacher,
    teachers,
    selectedTeacherId
  } = props;
  console.log('Language', Language, Subject, Grade);
  console.log('Subject', Subject);
  console.log('Grade', Grade);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = useState('');
  const [isEnableSubject, setisEnableSubject] = useState(true);
  const [isEnableTeacher, setisEnableTeacher] = useState(true);
  const [msg, setMsg] = useState(null);
  useEffect(() => {
    if (Language && Grade && image) {
      setisEnableSubject(false);
    }
  }, [Language, Grade, image]);
  useEffect(() => {
    if (Language && Grade && Subject && selectedTeacherId) {
      setisEnableTeacher(false);
    }
  }, [Language, Grade, Subject, selectedTeacherId]);
  const handleCompressImage = (e) => {
    const file = e.target.files;
    const compressPromises = [];

    if (file[0] && file[0].size > 1024 * 1024 * 2) {
      setMsg('please_upload_less_than_2MB');
    }
    setMsg('uploading');

    const compressPromise = new Promise((resolve, reject) => {
      new Compressor(file[0], {
        quality: 0.8,
        success(result) {
          const formData = new FormData();
          formData.append('file', result);
          dispatch(
            uploadFiles(formData, (url) => {
              setImage(url);
              resolve();
            })
          );
        },
        error(err) {
          console.error(err.message);
          reject(err);
        }
      });
    });

    compressPromises.push(compressPromise);
    Promise.all(compressPromises)
      .then(() => {
        console.log('All files processed and dispatched successfully');
        setMsg('please_Click_upload_button');
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };
  const addhandleSubject = () => {
    addSubject(image);
  };
  return (
    <Card>
      <CardContent
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'block',
          textAlign: 'center'
        }}
      >
        <Card style={{ backgroundColor: '#EDE4E3' }}>
          <CardContent>
            <h1>Add New Subject</h1>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="language"
                  onChange={handleChangeClick}
                  options={Language}
                  placeHolderText="select language"
                />
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="grade"
                  onChange={handleChangeClick}
                  options={Grade}
                  placeHolderText="select grade"
                />
              </FormControl>

              <InputLabel className={classes.label}>Subject</InputLabel>

              <OutlinedInput
                name="subject"
                onChange={handleSubjectChange}
                placeholder=""
                inputProps={{ 'aria-label': 'title' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div
                style={{
                  backgroundColor: 'gray',
                  padding: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                  width: '35%'
                }}
              >
                {msg && <span>{msg}</span>}
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleCompressImage}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
                <label
                  htmlFor="fileInput"
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    border: '1px solid black',
                    padding: '3px'
                  }}
                >
                  Choose Image
                </label>
              </div>
              <div
                style={{
                  backgroundColor: 'gray',
                  padding: '10px',
                  borderRadius: '10px',
                  marginTop: '10px',
                  width: '35%'
                }}
              >
                {image && <img src={image} style={{ width: '100px', height: '100px' }} />}
              </div>
              <Button variant="contained" onClick={addhandleSubject} disabled={isEnableSubject}>
                Add Subject
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card style={{ backgroundColor: '#EDE4E3' }}>
          <CardContent>
            <h1>Add New Teacher</h1>

            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="language"
                  onChange={handleChangeClick}
                  options={Language}
                  placeHolderText="select language"
                />
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="grade"
                  onChange={handleChangeClick}
                  options={Grade}
                  placeHolderText="select grade"
                />
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '150px' }}
              >
                <NameSelector
                  name="subject"
                  onChange={handleChangeClick}
                  options={Subject}
                  placeHolderText="select subject"
                />
              </FormControl>
              <Autocomplete
                id="teacher-id"
                options={teachers}
                getOptionLabel={(option) => option.fullName}
                style={{ width: '25%' }}
                renderInput={(params) => (
                  <TextField {...params} label="Select teacher" variant="outlined" />
                )}
                onChange={handleTeacherChange}
                value={teachers.find((teacher) => teacher.id === selectedTeacherId)}
                key={selectedTeacherId}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained" onClick={addhandleTeacher} disabled={isEnableTeacher}>
                Add Teacher
              </Button>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}

export default Addselector;
