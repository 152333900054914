import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  examPaperCard: {
    minWidth: 275,
    marginTop: 15,
    marginBottom: 15
  },
  questionNo: {
    float: 'right'
  }
}));

const ExamDetails = (props) => {
  const classes = useStyles();
  const { examPaperDetails, questionNo, allQuestionsAdded } = props;
  return (
    <div>
      <Card className={classes.examPaperCard} key={examPaperDetails.id}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h3>{examPaperDetails.title}</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!allQuestionsAdded ? (
                <p className={classes.questionNo}>Question no - {questionNo}</p>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default ExamDetails;
