import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import PendingExamPaperList from '../../components/ExamPaper/ExamPapersList';
import keycloakService from '../../keycloakService';
import DashboardLayout from '../../layouts/DashboardLayout';

class PendingExamPapers extends Component {
  componentDidMount() {
    const { fetchExamPapers } = this.props;
    const userDetails = keycloakService.getUserDetails();
    fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' });
  }

  render() {
    const { pendingExamPapers } = this.props;
    return (
      <Fragment>
        <DashboardLayout>
          <Container className="main-container">
            <PendingExamPaperList examList={pendingExamPapers} title={'Pending Exam Papers'} />
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pendingExamPapers: state.examPaper.pendingExamPapers
});

const mapDispatchToProps = {
  fetchExamPapers
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingExamPapers);
