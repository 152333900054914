import React, { useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { promoCodeGenerating } from '../../actions/pointAction';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
const promo = {
  promoCode: '',
  promoAmount: 0,
  usageLimit: 0
};
function PromoCodeSubscription() {
  const [promoCode, setPromoCode] = useState(promo);
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(true);
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const handleChange = (e) => {
    const { name, value } = e.target;

    promoCode[name] = value;
    setPromoCode(promo);
    if (promoCode.promoCode != '' && promoCode.promoAmount != 0 && promoCode.usageLimit != 0) {
      setdisable(false);
    }
  };
  const handlePromoCode = () => {
    dispatch(
      promoCodeGenerating(promoCode, (res) => {
        setMessage(
          res,
          setTimeout(() => {
            setMessage('');
          }, 3000)
        );
      })
    );
  };
  return (
    <DashboardLayout>
      <Card
        style={{
          border: '1px solid black',
          margin: '10% 25% 0 25%'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <h2
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              Email Base Points Topup For Student
            </h2>
            <Divider />
            {message != '' && <p style={{ color: 'green' }}>{message}</p>}
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>Promo Code</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoCode"
                  onChange={handleChange}
                  placeholder="Enter Promo Code"
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>Promo Amount</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoAmount"
                  onChange={handleChange}
                  placeholder="Enter Promo Amount Hear"
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>usage Limit</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="usageLimit"
                  onChange={handleChange}
                  placeholder="Enter Promo code Limit"
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  onClick={() => handlePromoCode()}
                  disabled={disable}
                  variant="outlined"
                  color="primary"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default PromoCodeSubscription;
