import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchHistoryTypingPaper } from '../../actions/typistAction';
import { useDispatch, useSelector } from 'react-redux';

function TypistHistory() {
  const historyPaperRequest = useSelector((state) => state.examPaperRequest.historyPaperRequest);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHistoryTypingPaper());
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Typist History Page
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Teacher Name</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyPaperRequest.length >= 1 &&
                    historyPaperRequest.map((papers, index) => (
                      <TableRow key={index}>
                        <TableCell>Date()</TableCell>
                        <TableCell>25/03/2023</TableCell>
                        <TableCell>{papers.teacherName}</TableCell>
                        <TableCell>{papers.subject}</TableCell>
                        <TableCell>{papers.title}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistHistory;
