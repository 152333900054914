import React from 'react';
import Select from '@material-ui/core/Select';

const Selector = (props) => {
  const { onChange, name, options, placeHolderText, value, error } = props;
  return (
    <Select
      native
      onChange={onChange}
      inputProps={{
        name: `${name}`,
        id: `${name}-native-simple`
      }}
      value={value}
      error={error}
    >
      <option aria-label="None" value="">
        {placeHolderText}
      </option>
      {options.map((option) => (
        <option value={option.id} key={option.id}>
          {option.value}
        </option>
      ))}
    </Select>
  );
};

export default Selector;
