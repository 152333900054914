import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Selector from '../Selector';
import FormHelperText from '@material-ui/core/FormHelperText';
import { hrs, mins, numbersOnly, decimalNumbersOnly, ROLE } from '../../utils/helper';
import { Box, Card, CardContent, Checkbox, Divider, FormControl } from '@material-ui/core';
import Description from '../ExamPaper/Description';
import NameSelector from '../Selector/NameSelector';
import keycloakService from '../../keycloakService';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  }
}));

export default function CreateExamForm(props) {
  const {
    handleCreate,
    adminhandleCreate,
    handleChange,
    handleChangeClick,
    handlePublic,
    handlePastPapers,
    handleDescription,
    handleTypistChange,
    handleSubjectChange,
    handleChangeDuration,
    examPaper,
    submitting,
    errorMsg,
    typists,
    allTeachers,
    selectedTypist,
    handleTeacherChange,
    selectedTeacher,
    handleCorrectorChange,
    errors,
    durationInMins,
    durationInHrs,
    initialValue,
    allowpublic,
    allowpastPaper,
    Language,
    Grade,
    Subject,
    finalPrice,
    availableCorrector,
    selectedCorrector
  } = props;
  const classes = useStyles();
  const descript = 'description';
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);
  console.log('examPaper', examPaper);
  console.log('hasTeacherRole', hasTeacherRole);
  const categoryOptionsTemp = [
    {
      id: 'MCQ',
      value: 'MCQ'
    },
    {
      id: 'ESSAY',
      value: 'ESSAY'
    }
  ];
  return (
    <Card>
      <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <form className={classes.root} noValidate autoComplete="off">
          <h2
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px'
            }}
          >
            Create Exam paper
          </h2>
          <Divider />
          {errorMsg ? <p className={classes.errorText}>{errorMsg}</p> : null}
          <Grid className={classes.container} container spacing={3}>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.lable}>Language</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <FormControl
                fullWidth
                style={{ border: '1px solid #bdc7bf', borderRadius: '5px', padding: '10px' }}
              >
                <NameSelector
                  name="language"
                  onChange={handleChangeClick}
                  options={Language}
                  placeHolderText="select language"
                  error={!!errors.language}
                />
              </FormControl>
              <FormHelperText id="language-helper-text">{errors.language || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Title</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <OutlinedInput
                name="title"
                value={examPaper.title}
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'title' }}
                fullWidth
                error={!!errors.title}
              />
              <FormHelperText id="title-helper-text">{errors.title || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Grade</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <FormControl
                fullWidth
                style={{ border: '1px solid #bdc7bf', borderRadius: '5px', padding: '10px' }}
              >
                <NameSelector
                  name="grade"
                  onChange={handleChangeClick}
                  options={Grade}
                  placeHolderText="select grade"
                  error={!!errors.grade}
                />
              </FormControl>
              <FormHelperText id="title-helper-text">{errors.grade || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Subject</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <FormControl
                fullWidth
                style={{ border: '1px solid #bdc7bf', borderRadius: '5px', padding: '10px' }}
              >
                <NameSelector
                  name="subject"
                  onChange={handleSubjectChange}
                  options={Subject}
                  placeHolderText="select grade"
                  error={!!errors.grade}
                />
              </FormControl>
              <FormHelperText id="title-helper-text">{errors.subject || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Category</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <FormControl
                fullWidth
                style={{ border: '1px solid #bdc7bf', borderRadius: '5px', padding: '10px' }}
              >
                <Selector
                  name="category"
                  onChange={handleChange}
                  options={categoryOptionsTemp}
                  placeHolderText="select Subject"
                  error={!!errors.category}
                />
              </FormControl>
              <FormHelperText id="title-helper-text">{errors.category || ''}</FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>No of Question</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <OutlinedInput
                name="noOfQuestions"
                value={examPaper.noOfQuestions}
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'no of question' }}
                fullWidth
                error={!!errors.noOfQuestions}
                onKeyPress={numbersOnly}
              />
              <FormHelperText id="title-helper-text">{errors.noOfQuestions || ''}</FormHelperText>
            </Grid>
            {examPaper.category == 'MCQ' && (
              <>
                <Grid item xs={6} sm={4}>
                  <InputLabel className={classes.label}>No of Answers</InputLabel>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <OutlinedInput
                    name="noOfAnswers"
                    value={examPaper.noOfAnswers}
                    onChange={handleChange}
                    placeholder=""
                    inputProps={{ 'aria-label': 'no of answer' }}
                    fullWidth
                    error={!!errors.noOfAnswers}
                    onKeyPress={numbersOnly}
                  />
                  <FormHelperText id="title-helper-text">{errors.noOfAnswers || ''}</FormHelperText>
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Duration (hh:mm)</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <FormControl
                style={{
                  width: '48%',
                  border: '1px solid #bdc7bf',
                  borderRadius: '5px',
                  padding: '10px'
                }}
              >
                <Select
                  name="durationInHrs"
                  value={durationInHrs}
                  labelId="select-duration-in-hrs"
                  id="select-duration-in-hrs"
                  onChange={handleChangeDuration}
                  error={!!errors.durationInMinute}
                >
                  <MenuItem value={0}>00</MenuItem>
                  {hrs.map((info, i) => (
                    <MenuItem key={i} value={info}>
                      {info}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span className={classes.right}>:</span>
              <FormControl
                style={{
                  width: '48%',
                  border: '1px solid #bdc7bf',
                  borderRadius: '5px',
                  padding: '10px'
                }}
              >
                <Select
                  name="durationInMins"
                  labelId="select-duration-in-minus"
                  id="select-duration-in-minus"
                  value={durationInMins}
                  onChange={handleChangeDuration}
                  required
                  error={!!errors.durationInMinute}
                >
                  <MenuItem value={0}>00</MenuItem>
                  {mins.map((info, i) => (
                    <MenuItem key={i} value={info}>
                      {info}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText id="title-helper-text">
                {errors.durationInMinute || ''}
              </FormHelperText>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Typist</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Autocomplete
                id="typist-id"
                name="typist"
                options={typists}
                getOptionLabel={(option) => option.fullName}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Typist"
                    variant="outlined"
                    error={!!errors.typist}
                  />
                )}
                onChange={handleTypistChange}
                value={selectedTypist}
              />
              <FormHelperText id="title-helper-text">{errors.typist || ''}</FormHelperText>
              {/*<CSVFileReader />*/}
            </Grid>
            {examPaper.category == 'ESSAY' && (
              <>
                <Grid item xs={6} sm={4}>
                  <InputLabel className={classes.label}>corrector</InputLabel>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Autocomplete
                    id="corrector-id"
                    name="corrector"
                    options={availableCorrector}
                    getOptionLabel={(option) => option.fullName}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Corrector"
                        variant="outlined"
                        error={!!errors.corrector}
                      />
                    )}
                    onChange={handleCorrectorChange}
                    value={selectedCorrector}
                  />
                  <FormHelperText id="title-helper-text">{errors.corrector || ''}</FormHelperText>
                  {/*<CSVFileReader />*/}
                </Grid>
              </>
            )}
            {hasAdminRole && (
              <>
                <Grid item xs={6} sm={4}>
                  <InputLabel className={classes.label}>Teacher</InputLabel>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Autocomplete
                    id="teacher-id"
                    options={allTeachers}
                    getOptionLabel={(option) => option.fullName}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Teacher"
                        variant="outlined"
                        error={!!errors.teacher}
                      />
                    )}
                    onChange={handleTeacherChange}
                    value={selectedTeacher}
                  />
                  <FormHelperText id="title-helper-text">{errors.teacher || ''}</FormHelperText>
                  {/*<CSVFileReader />*/}
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Price</InputLabel>
            </Grid>
            <Grid item xs={3} sm={4}>
              <OutlinedInput
                name="price"
                value={examPaper.price}
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'price' }}
                fullWidth
                error={!!errors.price}
                onKeyPress={decimalNumbersOnly}
              />
              <FormHelperText id="title-helper-text">{errors.price || ''}</FormHelperText>
            </Grid>
            <Grid item xs={3} sm={4}>
              <InputLabel className={classes.label}>
                <strong>Final Price Rs.{finalPrice}</strong>
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Allow to Public</InputLabel>
            </Grid>
            <Grid item xs={3} sm={2}>
              <Checkbox
                checked={allowpublic}
                onChange={handlePublic}
                inputProps={{ 'aria-label': 'controlled' }}
                name="public"
              />
            </Grid>
            <Grid item xs={3} sm={4}>
              <Box>
                <img
                  src={examPaper.coverImage}
                  style={{ width: '100px', hight: '100px', borderRadius: '10px' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Past Paper</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Checkbox
                checked={allowpastPaper}
                onChange={handlePastPapers}
                inputProps={{ 'aria-label': 'controlled' }}
                name="pastPaper"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>Exam instruction</InputLabel>
            </Grid>
            <Description
              input={initialValue}
              setInput={(val) => handleDescription(descript, val)}
            />
            <FormHelperText id="title-helper-text">{errors.description || ''}</FormHelperText>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item xs={6} sm={6}>
              <Button
                fullWidth
                onClick={hasTeacherRole && hasAdminRole ? adminhandleCreate : handleCreate}
                disabled={submitting}
                variant="outlined"
                color="primary"
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
