import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchAllTypists } from '../../actions/examPaperAction';
import { Autocomplete } from '@material-ui/lab';
import { findTypist } from '../../utils/helper';
import { reAssignTypist } from '../../actions/typistAction';
function TypistRejectedRequest() {
  const [open, setOpen] = useState({ id: '' });
  const [typist, setTypist] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const pendingExamPapers = useSelector((state) => state.examPaper.pendingExamPapers);
  const AllTypist = useSelector((state) => state.examPaper.typists);
  useEffect(() => {
    const userDetails = keycloakService.getUserDetails();
    dispatch(fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' })); //no point fetchExamPapers take as status=''
    dispatch(fetchAllTypists());
  }, []);
  const handleClick = (examPaper) => {
    examPaper.typist = typist;
    dispatch(reAssignTypist(examPaper.id, typist));
    history.push('/typing-papers');
  };
  const handleChange = (e, val, allPapers) => {
    e.preventDefault();
    setOpen({ id: allPapers.id });
    setTypist(val.id);
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Typist Rejected Paper Request
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table arial-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Typist Name</TableCell>
                    <TableCell>Subject Name</TableCell>
                    <TableCell>Subject Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Change Typist</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingExamPapers.length >= 1 &&
                    pendingExamPapers.map(
                      (allPapers, index) =>
                        allPapers.typistStatus === 'REQUEST_REJECTED' && (
                          <TableRow key={index}>
                            <TableCell>{allPapers.typistName}</TableCell>
                            <TableCell>{allPapers.subject}</TableCell>
                            <TableCell>{allPapers.title}</TableCell>
                            <TableCell>{allPapers.typistStatus}</TableCell>
                            <TableCell>
                              <Autocomplete
                                id="typist-id"
                                name="subject"
                                options={AllTypist}
                                getOptionLabel={(option) => option.fullName}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Typist" variant="outlined" />
                                )}
                                onChange={(e, val) => handleChange(e, val, allPapers)}
                                value={findTypist(AllTypist, allPapers)}
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => handleClick(allPapers)}
                                color="primary"
                                style={{ backgroundColor: '#7EC8E3' }}
                                disabled={open.id == allPapers.id ? false : true}
                              >
                                Update Typist
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistRejectedRequest;
