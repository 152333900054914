import React, { Component, Fragment } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Container } from '@material-ui/core';
import Profile from '../../components/Profile/Profile';
import { fetchProfileDetail } from '../../actions/userAction';
import { connect } from 'react-redux';
import { patchProfileDetail } from '../../actions/userAction';
import { uploadFiles } from '../../actions/examPaperAction';
import Compressor from 'compressorjs';

const USER = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  profileImage: ''
};

export class index extends Component {
  constructor() {
    super();
    this.state = {
      user: USER,
      errors: {},
      submitting: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
  }
  componentDidMount() {
    const { fetchProfileDetail } = this.props;
    fetchProfileDetail({});
  }
  componentDidUpdate(preProps) {
    const { profile } = this.props;
    if (preProps.profile != profile) {
      this.setState({ user: profile });
    }
  }
  handleUploadClick = (e) => {
    const { uploadFiles } = this.props;
    const { errors } = this.state;
    this.setState({ profileImage: 'url' });
    const name = e.target.name;
    errors[name] = '';
    const file = e.target.files[0];

    if (!file) {
      errors[name] = 'Please select an image';
      this.setState({ errors });
      return;
    }
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        const formData = new FormData();
        formData.append('file', result);
        uploadFiles(formData, (url) => {
          this.setState({ profileImage: url });
        });
      },
      error(err) {
        console.log(err.message);
      }
    });
  };
  handleChange = (e) => {
    const { user, errors } = this.state;
    const { name, value } = e.target;
    user[name] = value;
    errors[name] = '';
    this.setState({ user, errors });
  };
  handleUpdate = () => {
    const { user } = this.state;
    const { patchProfileDetail } = this.props;
    patchProfileDetail(user);
  };
  render() {
    return (
      <Fragment>
        <DashboardLayout>
          <Container className="main-container">
            <Profile
              {...this.state}
              handleChange={this.handleChange}
              handleUpdate={this.handleUpdate}
              handleUploadClick={this.handleUploadClick}
            />
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.userProfile
});
const mapDispatchToProps = {
  fetchProfileDetail,
  patchProfileDetail,
  uploadFiles
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
