import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

const SettlementDetails = () => {
  return (
    <DashboardLayout>
      <div>
        <h3>Coming soon</h3>
      </div>
    </DashboardLayout>
  );
};

export default SettlementDetails;
