import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SubscriptionsIcon from './images/subscription.png';
import PublishedExamPapersIcon from './images/published-exam-papers.png';
import Chart from 'react-apexcharts';
import IncomeIcon from './images/income.png';
import { Link } from 'react-router-dom';
import { getDashboardDetails } from '../../actions/userAction';
// import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

const monthsInOrder = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];
const getAllGraphData = (subscribedPapers = []) => {
  let graphData = [];
  if (subscribedPapers && subscribedPapers.length > 0) {
    subscribedPapers.map((a) => {
      let seriesData = { name: '', data: [] };
      seriesData.name = a.examTitle;
      let monthlyEarnings = a.monthlyEarning;
      const months = Object.keys(monthlyEarnings);
      if (months && months.length > 0) {
        months.sort(function (a, b) {
          return monthsInOrder.indexOf(a) - monthsInOrder.indexOf(b);
        });
        months.forEach((m) => {
          seriesData.data.push({ x: m, y: monthlyEarnings[m] });
        });
      }
      graphData.push(seriesData);
    });
  }
  return graphData;
};

const seriesData = {
  series: [],
  options: {
    chart: {
      type: 'area',
      height: 300,
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Overall',
      align: 'left'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      title: {
        text: 'Months'
      },
      type: 'category'
    },
    yaxis: {
      title: {
        text: 'Amount'
      }
    }
  }
};
// const seriesData2 = {
//   series: [44, 55, 13, 43, 22],
//   options: {
//     chart: {
//       width: 500,
//       type: 'pie'
//     },
//     labels: ['A', 'B', 'C', 'S', 'F'],
//     title: {
//       text: 'Paper Name',
//       align: 'left'
//     }
//   }
// };

class PendingExamPaper extends Component {
  constructor() {
    super();
    this.state = {
      isDataLoaded: false,
      graphData: seriesData
    };
  }
  componentDidMount() {
    const { getDashboardDetails } = this.props;
    getDashboardDetails();
  }

  componentDidUpdate(preProps) {
    const { dashboardInfo } = this.props;
    if (preProps.dashboardInfo !== dashboardInfo) {
      const { graphData } = this.state;
      const data = getAllGraphData(dashboardInfo.subscribedPapers);
      graphData.series = data;
      this.setState({ graphData, isDataLoaded: true });
    }
  }
  render() {
    const { dashboardInfo } = this.props;
    const { isDataLoaded, graphData } = this.state;
    // debugger; //eslint-disable-line
    console.log('graphData.series', graphData.series);
    //const array = graphData.series;
    // const newTemp = { 0: graphData.series[0], 1: graphData.series[1] };
    return (
      <Fragment>
        <DashboardLayout>
          <div className="dashboard-wrapper">
            <div className="title-info-header">
              <div className="d-flex align-items-center">
                <h2>Dashboard</h2>
              </div>
            </div>
            <div className="content-top-cards">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Paper className="card-hmdb card-animate">
                    <div className="card-body">
                      <div className="card-info-block">
                        <div>
                          <h2>{dashboardInfo.subscriptions}</h2>
                          <p className="text-muted">Subscriptions</p>
                          <Link className="btn-link">
                            View details{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </Link>
                        </div>
                        <div>
                          <div className="avatar-rt">
                            <span>
                              <img src={SubscriptionsIcon} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className="card-hmdb card-animate">
                    <div className="card-body">
                      <div className="card-info-block">
                        <div>
                          <h2>{dashboardInfo.publishedExams}</h2>
                          <p className="text-muted">Published Exam Papers</p>
                          <Link className="btn-link">
                            View all{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </Link>
                        </div>
                        <div>
                          <div className="avatar-rt">
                            <span>
                              <img src={PublishedExamPapersIcon} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className="card-hmdb card-animate">
                    <div className="card-body">
                      <div className="card-info-block">
                        <div>
                          <h2>{dashboardInfo.totalEarning}</h2>
                          <p className="text-muted">Monthly Income</p>
                          <Link className="btn-link">
                            View net earning{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </Link>
                        </div>
                        <div>
                          <div className="avatar-rt">
                            <span>
                              <img src={IncomeIcon} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                {/* <Grid item xs={3}>
                  <Paper className="card-hmdb card-animate">
                    <div className="card-body">
                      <div className="card-info-block">
                        <div>
                          <h2>0</h2>
                          <p className="text-muted">Product Deliveries</p>
                          <Link className="btn-link">
                            View net earning{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                              />
                            </svg>
                          </Link>
                        </div>
                        <div>
                          <div className="avatar-rt">
                            <span>
                              <img src={SubscriptionsIcon} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid> */}
              </Grid>
            </div>
            <div className="">
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Paper className="card-hmdb">
                    <div className="card-body">
                      <div className="card-info-block">
                        <h4 className="card-header">Income</h4>
                      </div>
                      <div className="income-chart-area">
                        {isDataLoaded ? (
                          <Chart
                            options={graphData.options}
                            series={graphData.series.slice(10, 20)}
                            type="area"
                            width={700}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Paper>
                </Grid>
                {/* <Grid item xs={4}>
                  <Paper className="card-hmdb">
                    <div className="card-body">
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Select Paper</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Chemistry"
                            control={<Radio />}
                            label="Chemistry"
                          />
                          <FormControlLabel
                            value="Organic_Chemistry"
                            control={<Radio />}
                            label="Organic Chemistry"
                          />
                          <FormControlLabel
                            value="inOrganic_Chemistry"
                            control={<Radio />}
                            label="inOrganic Chemistry"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div className="card-info-block">
                        <h4 className="card-header">Week Exam Report</h4>
                      </div>
                      <div className="exam-chart-area">
                        {isDataLoaded ? (
                          <Chart
                            options={seriesData2.options}
                            series={seriesData2.series}
                            type="pie"
                            width={400}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Paper>
                </Grid> */}
              </Grid>
            </div>
          </div>
          <Container className="main-container">
            {/* <History historyData={paperHistory} /> */}
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardInfo: state.user.dashboardInfo
});

const mapDispatchToProps = {
  getDashboardDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingExamPaper);
